<template>
  <div :class="{ 'mb-5': true, waiting: waiting }">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="input-group-append">
          <a class="btn btn-outline-primary float-right" @click="searchLog" title="Logs">
            <font-awesome-icon :icon="['fa', 'file']" /> Logs
          </a>
        </div>
        <br />
        <div class="alert alert-success fs-6" v-if="sucesso.length">
          <ul class="list-unstyled">
            <li v-for="(s, i) in sucesso" :key="i">{{ s }}</li>
          </ul>
        </div>
        <div class="alert alert-warning fs-6" v-if="alerta.length">
          <ul class="list-unstyled">
            <li v-for="(a, i) in alerta" :key="i">{{ a }}</li>
          </ul>
        </div>
        <div class="alert alert-danger fs-6" v-if="erro.length">
          <ul class="list-unstyled">
            <li v-for="(e, i) in erro" :key="i">{{ e }}</li>
          </ul>
        </div>
        <div class="mb-3"></div>
        <div class="accordion">
          <div class="accordion-item">
            <div class="accordion-header">
              <button :class="{ 'accordion-button': true, collapsed: uploaded }" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelImport" aria-expanded="true" aria-controls="panelImport">
                Importação de dados
              </button>
            </div>
            <div id="panelImport" :class="{
              'accordion-collapse': true,
              collapse: true,
              show: !uploaded,
            }">
              <div class="accordion-body">
                <div class="row pt-2">
                  <div class="col">
                    <p class="small">
                      <strong>
                        ⚠️ Evite repetir nomes de arquivos já enviados e
                        importados.
                      </strong>
                    </p>

                    <tus-client ref="tusclient" :chunk-size="chunkSize" :endpoint-tus="tusEndpoint"
                      :disabled="!tusEndpoint || waiting" :allowed-extensions="extensoesPermitidas" :disabledEnviar="!tusEndpoint || waiting || imported || canceled
                        " @progress-upload="progressHandle" @success-upload="successHandle" @error-upload="errorHandle"
                      @pause-upload="pauseHandle" @pause-uploads="pauseHandle" @remove-upload="resetHandle"
                      @reset-uploads="resetHandle"></tus-client>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <button :class="{ 'accordion-button': true, collapsed: !uploaded }" type="button" data-bs-toggle="collapse"
                data-bs-target="#panelResultado" aria-expanded="true" aria-controls="panelResultado">
                Resultado da importação de dados
              </button>
            </div>
            <div id="panelResultado" :class="{
              'accordion-collapse': true,
              collapse: true,
              show: uploaded,
            }">
              <div class="accordion-body">
                <div v-show="waiting" class="p-1">
                  <span :class="{ 'waiting-text': waiting }">Processando importação</span>
                  <div class="progress p-1" role="progressbar" aria-label="importacao" aria-valuenow="0" aria-valuemin="0"
                    aria-valuemax="100">
                    <div class="progress-bar bg-warning"
                      :style="'width: ' + percentImportacao + '%' + '; font-size: .8em; color: black'">
                      <b>{{ percentImportacao }}%</b>
                    </div>
                  </div>
                </div>
                <Logs v-model="logs" :num-lin="numLin" :file-loaded="fileLoaded" :imported="imported" :canceled="canceled"
                  @getLogs="refreshLogs" @downloadLogs="downloadLogs" @changeQtdLin="changeQtdLin" @new="novaImportacao">
                </Logs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TusClient from "../components/administracao/importacao/tus/TusClient.vue";
import Logs from "../components/administracao/importacao/logs/Logs.vue";

export default {
  name: "App",
  components: {
    TusClient,
    Logs,
  },
  computed: {
    http() {
      return this.$store.getters.http;
    },
    fileLoaded() {
      return !!this.tusArr[0]?.filename;
    },
    waiting() {
      return this.uploaded && this.percentImportacao != 100 && !this.canceled;
    },
    versao() {
      return this.$store.getters.const.sistema.versao || "";
    },
  },
  data() {
    return {
      modalShow: false,
      erro: [],
      sucesso: [],
      alerta: [],
      open: false,
      chunkSize: undefined,
      numLin: "5",
      imported: false,
      uploaded: false,
      canceled: false,
      pollInterval: null,
      secondsToPoll: null,
      percentImportacao: 0,
      qtdNoProgress: 0,
      maxNoProgressAllowed: null,
      tusEndpoint: "",
      extensoesPermitidas: ["txt", "csv"],
      logs: ["Aguardando importação..."],
      tusArr: [],
      items: [
        { text: "Admin", to: { name: "admin" } },
        { text: "Importação de dados - Famílias", active: true },
      ],
    };
  },
  methods: {
    async getRetry(url, options, n) {
      try {
        return await this.http.get(url, options);
      } catch (err) {
        if (n === 1) throw err;
        return await this.getRetry(url, options, n - 1);
      }
    },
    verificaErros(responseData) {
      let err = [];
      // cabecalho invalido
      if (responseData.completed == -1) {
        err.push(`Cabeçalho inválido ou inexistente. Importação cancelada.`);
        this.cancel();

        // importacao cancelada
      } else if (responseData.completed == -2) {
        err.push(`Importação cancelada.`);
        this.cancel();

        // verificando se o progresso esta parado
      } else if (this.qtdNoProgress >= this.maxNoProgressAllowed) {
        err.push(
          `Tentativa de importação mal sucedida, tente novamente em outro momento. Se o erro persistir, entre em contato com o administrador.`
        );
        this.alerta = [];
        this.cancel();

        // verificando se ha falha em lotes
      } else if (responseData.fail) {
        let lotes = responseData.fail.split("|");
        lotes.forEach((lote) => {
          if (lote) err.push(`Falha no lote ${lote} de importação.`);
          err = Array.from(new Set(err));
        });
      }
      err = Array.from(new Set(err));

      return err;
    },
    async verificaStatusImportacao(tusId) {
      if (!this.uploaded) return;
      if (this.canceled) return;
      const urlBack = this.$env.VUE_APP_BACKEND_URL;
      const status = await this.getRetry(
        `${urlBack}/admin/import/status/${tusId}`,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        },
        3
      );
      // calculando chamadas sem progresso
      this.qtdNoProgress =
        this.percentImportacao != status.data.progress
          ? 0
          : this.qtdNoProgress + 1;
      this.percentImportacao = status.data.progress;

      let erros = this.verificaErros(status.data);
      this.erro = erros;

      // processando importacoes
      if (status.data.completed == 0) {
        this.imported = false;
        this.sucesso = [];
        if (!this.canceled) {
          this.alerta = [
            `Processando importações. Aguarde a finalização para baixar o log completo (você pode clicar no botão de atualização na área 'Resultado da importação de dados' abaixo para atualizar o log parcial).`,
          ];
        }

        // processamento finalizado
      } else if (status.data.completed == 1) {
        this.imported = true;
        this.percentImportacao = 100;
        if (this.pollInterval) {
          clearInterval(this.pollInterval);
          this.pollInterval = null;
        }
        this.alerta = [];
        if (erros.length == 0) {
          this.sucesso = [
            "Processamento de importação finalizado. Você pode clicar no botão 'Baixar Log' na área 'Resultado da importação de dados' abaixo para baixar o log completo da importação.",
          ];
          this.erro = [];
        } else {
          this.erro.push(`Importação finalizada com erros.`);
          this.sucesso = [];
        }
        this.alerta = [];
      }
    },
    pollStatusImportacao() {
      this.pollInterval = setInterval(async () => {
        this.refreshLogs(this.numLin, false);
      }, this.secondsToPoll * 1000);
    },
    progressHandle(up) {
      this.alerta = ["Processando..."];
      if (!this.pollInterval && !this.imported) {
        try {
          this.pollStatusImportacao(up.tusId);
        } catch (error) {
          console.error("Erro ao chamar pollStatusImportacao", error);
          console.error("Tentando novamente", error);
          this.pollStatusImportacao(up.tusId);
        }
      }
      const tus = this.tusArr.find((u) => u.tusId === up.tusId);
      if (up.tusId && !tus) {
        this.tusArr.push({
          tusId: up.tusId,
          filename: up.upload.options.metadata.filename,
          upload: up.upload,
        });
      }
    },
    successHandle() {
      this.refreshLogs(this.numLin);
      this.sucesso.push("Upload finalizado com sucesso!");
      this.uploaded = true;
      this.erro = [];
      this.alerta = [];
    },
    errorHandle(event) {
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
        this.pollInterval = null;
      }
      if (event.msg) {
        this.erro.push(event.msg);
      } else {
        this.erro.push(
          "Erro ao processar a importação. Clique em 'Limpar' e tente novamente. Se o erro persistir, entre em contato com a equipe de suporte."
        );
      }
    },
    pauseHandle() {
      this.alerta = ["Processamento pausado."];
    },
    cancel() {
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
        this.pollInterval = null;
      }
      this.sucesso = [];
      this.alerta = [];
      this.uploaded = true;
      this.imported = false;
      this.canceled = true;
      this.percentImportacao = 0;
      this.qtdNoProgress = 0;
      this.logs = ["Aguardando importação..."];
    },
    resetHandle() {
      this.cancel();
      this.erro = [];
      this.tusArr = [];
      this.uploaded = false;
      this.canceled = false;
    },
    async refreshLogs(qtdLinhas, showLoadingOverlay = true) {
      if (showLoadingOverlay) this.$store.commit("setLoading", true);
      // aproveita o refresh para verificar o status
      this.verificaStatusImportacao(this.tusArr[0]?.tusId);

      try {
        const tusId = this.tusArr[0]?.tusId;
        if (!tusId) {
          this.logs = ["Nenhum arquivo foi enviado para upload."];
          if (showLoadingOverlay) this.$store.commit("setLoading", false);
          return;
        }
        const url = `${this.$env.VUE_APP_BACKEND_URL}/admin/import/logs/show/${tusId}/${qtdLinhas}`;
        const resp = await this.http.get(url, {
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        });
        const logs = resp.data.split("\n");
        this.logs = logs;
        if (showLoadingOverlay) this.$store.commit("setLoading", false);
      } catch (error) {
        if (showLoadingOverlay) this.$store.commit("setLoading", false);
      }
    },
    downloadLogs() {
      this.$store.commit("setLoading", true);
      try {
        const filename = this.tusArr[0]?.filename;
        const tusId = this.tusArr[0]?.tusId;
        if (!this.fileLoaded) return false;
        if (!this.imported) return false;
        const url = `${this.$env.VUE_APP_BACKEND_URL}/admin/import/logs/download/r/${tusId}`;

        //Create XMLHTTP Request.
        const req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.getters.token}`
        );
        req.setRequestHeader("X-Client-Version", this.versao);
        req.setRequestHeader(
          "Access-Control-Allow-Headers",
          "X-Client-Version"
        );
        req.responseType = "blob";
        req.onload = () => {
          //Convert the Byte Data to BLOB object.
          const blob = new Blob([req.response], {
            type: "text/plain;charset=utf-8",
          });

          //Check the Browser type and download the File.
          const isIE = false || !!document.documentMode;
          if (isIE) {
            window.navigator.msSaveBlob(blob, `${filename}_LOG_COMPLETO.txt`);
          } else {
            const url = window.URL || window.webkitURL;
            const link = url.createObjectURL(blob);
            const a = document.createElement("a");
            a.setAttribute("download", `${filename}_LOG_COMPLETO.txt`);
            a.setAttribute("href", link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
          this.$store.commit("setLoading", false);
        };
        req.send();
      } catch (error) {
        this.$store.commit("setLoading", false);
      }
    },
    changeQtdLin(num) {
      this.numLin = num;
    },
    searchLog() {
      this.$router.push({ name: "log" });
    },
    async novaImportacao() {
      this.$refs['tusclient'].reset();
    }
  },
  mounted() {
    this.chunkSize = this.$env.IMPORT_CHUNK_SIZE;
    this.tusEndpoint = this.$env.IMPORT_TUS_ENDPOINT;
    this.secondsToPoll = this.$env.VUE_APP_IMPORT_SECONDS_TO_POLL || 5;
    this.maxNoProgressAllowed = this.$env.IMPORT_MAX_NO_PROGRESS_ALLOWED || 10;
    if (!this.tusEndpoint) {
      this.erro.push("O enpoint de importação não foi configurado.");
    }
  },
  beforeRouteLeave(to, from, next) {
    //se o arquivo foi carregado mas a importacao ainda nao terminou
    if (this.uploaded && !this.imported && !this.canceled) {
      const msg = `
Deseja mesmo sair desta tela?
Caso saia desta tela a importação continuará sendo executada, mas não será possível visualizar o resultado da mesma.
`;
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: `Confirmação de saída da tela`,
          size: "md",
          buttonSize: "md",
          okVariant: "warning",
          okTitle: "Sair mesmo assim",
          cancelTitle: "Cancelar",
          headerClass: "bg-warning",
        })
        .then((value) => {
          if (value === true) {
            this.resetHandle();
            next();
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
          console.error(`Refuse to leave the page`, from, to);
        });
    } else {
      next();
    }
  },
};
</script>
<style>
.waiting {
  cursor: wait !important;
}

.waiting-text {
  font-size: small;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
