<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">Pesquisa de Logs</div>
          <div class="card-body p-3">
            <fieldset class="border p-3 m-3">
              <legend class="float-none w-auto lead">Pesquise por</legend>
              <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                <div class="col">
                  <the-mask class="form-control" id="busca_data_importacao" type="text" mask="##/##/####" :masked="true"
                    placeholder="Data Importação" title="Data da Importação" v-model="dataImportacao"
                    @keyup.native.enter="buscar"></the-mask>
                </div>
                <div class="col">
                  <input class="form-control" id="busca_nome_arquivo" type="text" placeholder="Nome Arquivo"
                    title="Nome do Arquivo" v-model="nomeArquivo" @keyup.enter="buscar" />
                </div>
                <div class="col">
                  <input class="form-control" id="busca_usuario" type="text" placeholder="Usuário" title="Usuário"
                    v-model="usuario" @keyup.enter="buscar" />
                </div>
                <div class="col">
                  <select class="form-select" title="Status" aria-label="Default select example" v-model="erro"
                    @keyup.enter="buscar">
                    <option value="" :selected="erro != 'E' && erro != 'S'">
                      Status...
                    </option>
                    <option value="E" :selected="erro == 'E'">ERRO</option>
                    <option value="S" :selected="erro == 'S'">SUCESSO</option>
                  </select>
                </div>
                <div class="col col-lg-1 text-end">
                  <a class="btn btn-outline-warning w-100" title="Limpar" @click="limpar">
                    <font-awesome-icon :icon="['fas', 'times']" />
                    <span class="ms-2 d-inline d-lg-none">Limpar</span>
                  </a>
                </div>
                <div class="col col-lg-1 text-end">
                  <a class="btn btn-outline-success w-100" title="Pesquisar" @click="buscar">
                    <font-awesome-icon :icon="['fas', 'search']" />
                    <span class="ms-2 d-inline d-lg-none">Buscar</span>
                  </a>
                </div>
              </div>
            </fieldset>

            <b-card no-body>
              <b-card-header size="sm">
                <b-row>
                  <b-col>
                    <b-input-group prepend="Quantidade por página" class="mb-2 mr-sm-2 mb-sm-0">
                      <b-form-select v-model="tabelaLog.paginacao.per_page" size="sm" :options="tabelaLog.pageOptions"
                        @change="changePaginacao()">
                      </b-form-select>
                    </b-input-group>
                  </b-col>
                  <b-col align="right">
                    <a v-if="tabelaLog.items.length > 0" class="btn btn-outline-secondary"
                      title="Baixar arquivo completo de logs" @click="downloadLog">
                      <font-awesome-icon :icon="['fa', 'file-download']" />
                    </a>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-table :fields="tabelaLog.fields" :items="tabelaLog.items" responsive small hover stacked="md"
                :show-empty="tabelaLog.showEmpty" :empty-text="tabelaLog.emptyText">
                <template v-slot:cell(msgReduzida)="row">
                  <a :title="row.item.mensagem">{{ row.item.msgReduzida }}</a>
                </template>
              </b-table>
              <template v-slot:footer>
                <b-row>
                  <b-col>
                    <b-input-group v-if="tabelaLog.items.length > 0">
                      <template #prepend>
                        <b-input-group-text><strong class="text-success">Importados sucesso</strong></b-input-group-text>
                      </template>
                      <b-form-input disabled v-model="tabelaLog.sucesso">
                      </b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <b-input-group v-if="tabelaLog.items.length > 0">
                      <template #prepend>
                        <b-input-group-text><strong class="text-danger">Importados erro</strong></b-input-group-text>
                      </template>
                      <b-form-input disabled v-model="tabelaLog.erro"></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <b-pagination align="right" v-if="tabelaLog.paginacao.total > tabelaLog.paginacao.per_page
                      " :total-rows="tabelaLog.paginacao.total" :per-page="tabelaLog.paginacao.per_page"
                      :value="tabelaLog.paginacao.current_page" @change="changePaginacao">
                    </b-pagination>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import helpers from "../helpers";
import FileSaver from "file-saver";
import Paginacao from "../models/Paginacao";

export default {
  components: {
    TheMask,
  },
  data() {
    return {
      dataImportacao: "",
      nomeArquivo: "",
      usuario: "",
      erro: "",
      items: [
        { text: "Admin", to: { name: "admin" } },
        { text: "Importação de dados", to: { name: "importacao-familias" } },
        { text: "Log", active: true },
      ],
      tabelaLog: {
        paginacao: new Paginacao(),
        items: [],
        sucesso: 0,
        erro: 0,
        fields: [
          {
            key: "id",
            label: "Id",
            class: "text-center",
          },
          {
            key: "cpf_responsavel",
            label: "Cpf	",
            class: "text-center",
          },
          {
            key: "data_importacao",
            label: "Data Importação	",
            class: "text-center",
          },
          {
            key: "status",
            label: "Status",
            class: "text-center",
          },
          {
            key: "usuario_importacao",
            label: "Usuario Importação",
            class: "text-center",
          },
          {
            key: "nome_arquivo",
            label: "Arquivo",
            class: "text-center",
          },
          {
            key: "msgReduzida",
            label: "Mensagem",
            class: "text-center",
          },
        ],
        pageOptions: [10, 25, 50],
        showEmpty: true,
        emptyText: "Nenhum registro encontrado",
      },
    };
  },

  computed: {
    http() {
      return this.$store.getters.http;
    },
  },

  methods: {
    async buscar() {
      try {
        let dataImportacao = this.dataImportacao
          ? this.dataImportacao.replace(/\//g, "-")
          : "";
        this.$store.commit("setLoading", true);
        const resp = await this.http.get(
          `/admin/import/logs/search?status=${this.erro}&dataImportacao=${dataImportacao}&nomeArquivo=${this.nomeArquivo}&usuario=${this.usuario}&per_page=${this.tabelaLog.paginacao.per_page}&current_page=${this.tabelaLog.paginacao.current_page}`,
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
          }
        );
        this.tabelaLog.items = [];

        this.tabelaLog.paginacao = new Paginacao(resp.data.dados.logs);
        if (resp.data.dados.logs.data.length > 0) {
          resp.data.dados.logs.data.forEach((element) => {
            let msg = element.mensagem.replace("\n", "");

            element.msgReduzida =
              msg.length < 70 ? msg : msg.substring(0, 74) + "...";
            element.data_importacao = helpers.formatDateHourToDateTimeBr(
              element.data_importacao
            );
            this.tabelaLog.items.push(element);
          });
          this.tabelaLog.sucesso = resp.data.dados.numSucessos;
          this.tabelaLog.erro = resp.data.dados.numErros;
          this.tabelaLog.totalRows = this.tabelaLog.items.length;
        } else {
          this.tabelaLog.sucesso = 0;
          this.tabelaLog.erro = 0;
        }
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.$store.commit("setLoading", false);
      }
    },

    changePaginacao() {
      this.buscar();
    },

    limpar() {
      this.dataImportacao = "";
      this.nomeArquivo = "";
      this.usuario = "";
      this.erro = "";
      this.tabelaLog.items = [];
    },
    downloadLog() {
      var logs = [];
      logs = `===================================================================================\n`;
      logs += `Id\tCpf\tDataImportação\tStatus\tUsuárioImportação\tArquivo\tMensagem\n`;
      logs += `-----------------------------------------------------------------------------------\n`;
      this.tabelaLog.items.forEach((log) => {
        logs += `${log.id}\t${log.cpf_responsavel}\t${log.data_importacao}\t${log.status}\t${log.usuario_importacao}\t${log.nome_arquivo}\t${log.mensagem}\t\n`;
      });
      logs += `-----------------------------------------------------------------------------------\n`;
      logs += `SUCESSO: ${this.tabelaLog.sucesso}\tERRO: ${this.tabelaLog.erro}\t`;

      var blob = new Blob([logs], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, "LOG_COMPLETO" + ".txt");
    },
  },
};
</script>
