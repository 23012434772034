<template>
  <div>
    <b-modal :id="'modal-confirm-file-' + indice" header-bg-variant="warning">
      <template #modal-header>
        <h5>Envio de arquivo repetido</h5>
      </template>
      <p class="my-4">
        Tem certeza de que deseja re-enviar o arquivo
        <code>{{ nomeArquivo }}</code>?
        <br />
        O mesmo arquivo já foi enviado anteriormente.
        <br />
        Você deseja enviá-lo novamente?
      </p>
      <template #modal-footer="{ close }">
        <b-button variant="warning" @click="callbackSubstituir(indice)">
          Enviar novamente
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancelar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    callbackSubstituir: { type: Function, required: true },
    indice: { type: Number, default: 0 },
    nomeArquivo: { type: String, default: "" },
  },
};
</script>
