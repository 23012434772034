<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">Inativar famílias</div>
          <div class="card-body p-3">
            <div class="alert alert-success" v-if="sucesso">{{ sucesso }}</div>
            <div class="alert alert-warning" v-if="alerta">{{ alerta }}</div>
            <div class="alert alert-danger" v-if="error">{{ error }}</div>
            <div class="align-middle text-center">
              <div class="row">
                <div class="col">
                  <p>
                    Atenção: TODAS as famílias serão inativadas.
                    <br />
                    Essa operação é irreversível.
                    <br />
                    Tem certeza de que deseja continuar?
                    <br />
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button class="btn btn-danger" @click="inativar" :disabled="isPeriodoValid">
                    Inativar famílias
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";

export default {
  data() {
    return {
      items: [{ text: "Inativar famílias", active: true }],
      error: null,
      sucesso: null,
      alerta: null,
      isPeriodoValid: false,
    };
  },
  computed: {
    http() {
      return this.$store.getters.http;
    },
  },
  methods: {
    inativar() {
      const msg = `
Deseja mesmo inativar todas as famílias? Essa operação é irreversível.
`;
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: `Confirmação de inativação das famílias`,
          size: "md",
          buttonSize: "md",
          okVariant: "warning",
          okTitle: "Inativar",
          cancelTitle: "Cancelar",
          headerClass: "bg-warning",
        })
        .then(async (value) => {
          if (value === true) {
            this.$store.commit("setLoading", true);
            const url = `${this.$env.VUE_APP_BACKEND_URL}/admin/inativar-familias`;
            try {
              const resp = await this.http.get(url, {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.token}`,
                },
              });
              if (resp.data.dados.erro !== "true") {
                // this.sucesso = `Sucesso! ${resp.data.dados.message.qtdIntivados} famílias foram inativadas.`;
                this.sucesso = `Sucesso! Todas as famílias foram inativadas.`;
              } else {
                this.error = `Erro! ${resp.data.dados.message}`;
              }
              this.$store.commit("setLoading", false);
            } catch (error) {
              this.error = `Erro! ${error}`;
              console.error(error);
              this.$store.commit("setLoading", false);
            }
          }
        })
        .catch((err) => {
          // An error occurred
          this.error = `Erro! ${err}`;
          console.error(err);
          this.$store.commit("setLoading", false);
        });
    },

    async verificaIsPeriodoValid() {
      this.$store.commit("setLoading", true);
      try {

        const url = `${this.$env.VUE_APP_BACKEND_URL}/admin/verifica-validade`;
        const resp = await this.http.get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        });
        this.isPeriodoValid = resp.data.dados.message.valido == true;
        if (this.isPeriodoValid) {
          this.alerta = 'As famílias só poderão ser inativadas depois do término do período de validade dos vouchers';
        }
        this.$store.commit("setLoading", false);

      } catch (error) {
        // An error occurred
        this.error = `Erro! ${error}`;
        console.error(error);
        this.$store.commit("setLoading", false);
      }
    }
  },
  mounted() {
    this.verificaIsPeriodoValid();

    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    // eslint-disable-next-line no-unused-vars
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  },
};
</script>

<style scoped>
.error {
  color: #dc3545;
  text-align: right;
}
</style>
