<template>
  <div>
    <!-- ==================== tabela ==================== -->
    <b-table
      hover
      small
      caption-top
      :items="itens"
      :fields="fields"
      stacked="md"
      :current-page="currPage"
      :busy="loading"
      :per-page="perPage"
    >
      <!-- ----------------------- detalhes ----------------------- -->
      <template v-slot:row-details="row">
        <detalhe-historico :historico="row.item"></detalhe-historico>
      </template>

      <!-- ----------------- cpf (filtro) ------------------------- -->
      <template v-slot:cell(cpf)="row">
        <span>{{ row.item.cpf | formataCpf }}</span>
      </template>

      <!-- ------------------ acoes -------------------------------- -->
      <template v-slot:cell(acoes)="row">
        <lista-acoes :disabled="loading" :historico="row.item" @visualizar="row.toggleDetails"></lista-acoes>
      </template>
    </b-table>

    <!-- ==================== paginacao ==================== -->
    <b-pagination
      v-model="currPage"
      :total-rows="qtd"
      :per-page="perPage"
      size="sm"
      class="mt-1"
      number-of-pages="5"
      align="right"
      v-if="qtd>0"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  components: {
    "detalhe-historico": require("./DetalheHistorico.vue").default,
    "lista-acoes": require("./ListaAcoes.vue").default
  },
  props: {
    value: { type: Array, default: () => [{}] },
    paginate: { type: Boolean, default: true },
    perPage: { type: Number, default: 10 }
  },
  data() {
    return {
      currPage: 1,
      totalRows: null,
      hists: [],
      loading: false
    };
  },
  computed: {
    http() {
      return this.$store.getters.http;
    },
    qtd() {
      return this.totalRows || this.hists.length;
    },
    itens() {
      return this.hists;
    },
    fields() {
      return [
        { key: "cpf", label: "CPF", sortable: true, class: "text-left" },
        { key: "nome", label: "Nome", class: "text-left" },
        { key: "mes", label: "Mês", class: "text-left" },
        { key: "acoes", label: "Ações", class: "text-right" }
      ];
    }
  },
  watch: {
    value() {
      this.hists = this.value ? [...this.value] : this.hists;
    }
  },
  methods: {},
  mounted() {
    this.hists = this.value ? [...this.value] : this.hists;
  }
};
</script>

<style>
</style>