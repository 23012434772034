<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">Edição de dados de retirada de beneficiário</div>
          <div class="card-body p-3">
            <busca-beneficiarios ref="busca" @resultado-busca="getResultado"></busca-beneficiarios>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1 row">
      <resultado-busca @familia-inativada="$refs['busca'].buscar()" :value="benes"></resultado-busca>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "resultado-busca": require("../components/administracao/ResultadoBuscaFlagRetirado.vue").default,
    "busca-beneficiarios": require("../components/administracao/EditaFlagRetirado.vue").default
  },
  data() {
    return {
      items: [{ text: "Admin" }, { text: "Editar dados de retirada", active: true }],
      benes: [],
    };
  },
  methods: {
    getResultado(listaBenes) {
      this.benes = listaBenes;
    }
  },
};
</script>

