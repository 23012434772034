<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">Histórico de vouchers do beneficiário</div>
          <div class="card-body p-3">
            <busca-beneficiarios :show-nome="false" :is-historico="true" :search-on-mount="shouldSearch"></busca-beneficiarios>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1 row">
      <resultado-busca></resultado-busca>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "resultado-busca": require("../components/administracao/ResultadoBuscaHistorico.vue").default,
    "busca-beneficiarios": require("../components/administracao/BuscaBeneficiarios.vue").default
  },
  data() {
    return {
      items: [
        { text: "Admin", to: { name: "admin" } },
        { text: "Histórico de vouchers do Beneficiário", active: true }
        ],
    };
  },
  computed: {
    shouldSearch() {
      return !this.$store.getters.lstHistoricoVouchers && !!this.$store.getters.termoBuscaCpfHist;
    }
  },
  methods: {
  },
};
</script>

