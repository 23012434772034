<template>
  <IconButton
    :clickHandler="clickHandler"
    :height="height"
    :title="title"
    :disabled="disabled"
    viewBox="0 0 320 512"
    path="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"
  ></IconButton>
</template>

<script>
import IconButton from "../../IconButton.vue";
export default {
  props: {
    clickHandler: { type: Function, default: () => {} },
    height: { type: String, default: "2em" },
    title: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  components: {
    IconButton,
  },
};
</script>
