<template>
  <div>
    <b-link
      :disabled="disabled"
      class="text-info ml-2"
      title="Visualizar"
      @click="$emit('visualizar')"
    >
      <font-awesome-icon :icon="['fas', 'eye']" />
    </b-link>
  </div>
</template>

<script>
export default {
    props: {
        historico: { type: Object, default: () => {} },
        disabled: { type: Boolean, default: false },
    },
};
</script>

<style>
</style>