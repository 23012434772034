var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":"fileElem","multiple":_vm.multiple,"accept":this.acceptExt},on:{"change":_vm.adicionaArquivos}}),_c('div',{class:{
    alert: true,
    'alert-light': true,
    'text-center': true,
    'tus-client': true,
    disabled: _vm.disabled,
    readonly: _vm.readonly,
  }},[_c('div',{staticClass:"p-1"},[_vm._v(" Carregue e solte um arquivo ou clique na área abaixo para fazer upload. ")]),_c('div',{staticStyle:{"background-color":"rgba(0,0,0, 0)"},attrs:{"id":"drag-area-hidden"},on:{"click":_vm.abreFileDialog,"dragover":_vm.dragoverHandler,"drop":_vm.soltaArquivos,"dragenter":_vm.dragenterHandler,"dragexit":_vm.dragexitHandler,"dragend":_vm.dragendHandler}},[_c('div',{class:{ 'drag-enter': _vm.dragging, 'drop-area': true, 'p-4': true },attrs:{"id":"drag-area"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadsLen == 0 && !_vm.dragging),expression:"uploadsLen == 0 && !dragging"}]},[_c('IconUpload',{attrs:{"title":"carregue arquivos aqui","disabled":_vm.disabled,"clickHandler":_vm.abreFileDialog}})],1),(_vm.uploadsLen > 0)?_c('div',{staticClass:"row row-cols-1"},_vm._l((_vm.tusUploads),function(upload,key){return _c('div',{key:key,staticClass:"col"},[(upload)?_c('BlocoArquivo',{attrs:{"nome":upload?.options?.metadata?.filename,"percent":_vm.tusUploadsPercentages[key],"uploading":_vm.tusUploadsUploading[key],"play-handler":() => _vm.startOrResumeUpload(key),"pause-handler":() => _vm.pauseUpload(key),"cancel-handler":() => _vm.removeArquivo(key)}}):_vm._e(),_c('ArquivoRepetidoDialog',{attrs:{"indice":key,"nome-arquivo":upload?.options?.metadata?.filename,"callback-substituir":() => _vm.mdlSubstituir(key)}})],1)}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dragging),expression:"dragging"}],staticClass:"dragging"},[_c('div',{staticClass:"row pt-1"},[_c('div',{staticClass:"col"},[_c('IconDrop',{attrs:{"title":"solte arquivos aqui"}})],1)]),_vm._m(0)])])]),_c('div',{staticClass:"row pt-2"},[(_vm.uploadingLen <= 0)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-grid"},[_c('button',{class:{
            btn: true,
            'btn-success': true,
            disabled: _vm.disabled || _vm.disabledEnviar,
          },on:{"click":_vm.enviar}},[_vm._v(" Enviar ")])])]):_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-grid"},[_c('button',{class:{ btn: true, 'btn-danger': true, disabled: _vm.disabled },on:{"click":_vm.pausar}},[_vm._v(" Pausar ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-grid"},[_c('button',{class:{
            btn: true,
            'btn-secondary': true,
            disabled: _vm.disabled,
          },on:{"click":_vm.reset}},[_vm._v(" Limpar ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',[_vm._v("solte o arquivo aqui")])])])
}]

export { render, staticRenderFns }