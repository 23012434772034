<template>
  <div>
    <div style="width: 100%" id="logs" :class="{ 'p-3': true, logs: true }">
      <BarraComandosLogs v-model="qtdLin" :show="showBar" :file-loaded="fileLoaded" :imported="imported"
        :canceled="canceled" @refresh="getLogs" @copy="copyLogs" @download="downloadLogs" @clear="clearLogs"
        @new="newImport">
      </BarraComandosLogs>

      <!-- <span v-for="(line, i) in value" :key="i"> {{ line }}<br /> </span> -->
      <textarea id="textarea-logs" readonly :value="text" class="textarea-logs" rows=" 15" cols="300">
      </textarea>

      <div id="toasttextocopiado" class="toast align-items-center position-absolute top-0 start-50 translate-middle-x"
        style="width: 20em; z-index: 200" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">Texto copiado!</div>
          <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BarraComandosLogs from "./BarraComandosLogs.vue";
import { Toast } from "bootstrap";


export default {
  props: {
    value: { type: Array, default: () => ["----------"] },
    numLin: { type: String, default: "10" },
    fileLoaded: { type: Boolean, default: false },
    imported: { type: Boolean, default: false },
    canceled: { type: Boolean, default: false },
  },
  components: {
    BarraComandosLogs
  },
  data() {
    return {
      showBar: true,
      qtdLin: "10",
      valueInterno: [],
    };
  },
  computed: {
    text() {
      return this.value.join("\n");
    },
  },
  watch: {
    valueInterno() {
      document.getElementById("logs").scrollTop =
        document.getElementById("logs").scrollHeight;
      this.$emit("input", this.valueInterno);
    },
    qtdLin() {
      this.$emit("changeQtdLin", this.qtdLin);
    },
  },
  methods: {
    clearLogs() {
      this.valueInterno = ["Aguardando importação..."];
      this.$emit("clearLogs", this.qtdLin);
    },
    newImport() {
      this.valueInterno = ["Aguardando importação..."];
      this.$emit("new");
    },
    getLogs() {
      this.$emit("getLogs", this.qtdLin);
    },
    copyLogs() {
      let copyText = document.getElementById("textarea-logs");
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      let e = document.getElementById("toasttextocopiado");
      let t = Toast.getOrCreateInstance(e, { delay: 2000 });
      t.show();
    },
    downloadLogs() {
      this.$emit("downloadLogs");
    },
  },
  mounted() {
    this.qtdLin = this.numLin;
    this.valueInterno = this.value;
  },
};
</script>
<style>
.logs {
  position: relative;
  color: gray;
  min-height: 10vh;
  max-height: 40vh;
  width: 100%;
}

.textarea-logs {
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  font-size: small;
  font-family: "Courier New", Courier, monospace;
}
</style>
