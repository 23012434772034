<template>
  <div
    class="py-2 my-2"
    style="background-color: rgba(200, 200, 200, 0.3); cursor: pointer"
  >
    <div class="row justify-content-center align-items-center">
      <div class="col-2">
        <IconCsv
          title="arquivo csv"
          height="4vh"
          class="px-2"
          :click-handler="() => {}"
        ></IconCsv>
      </div>
      <div class="col-2">
        <ButtonPlay
          :clickHandler="play"
          title="enviar arquivo"
          height="1vh"
          class="me-2"
          v-if="!uploading && percent != 100"
          ref="btnPlay"
        ></ButtonPlay>
        <ButtonPause
          :clickHandler="pause"
          title="pausar envio de arquivo"
          height="1vh"
          class="me-2"
          v-if="uploading && percent != 100"
        ></ButtonPause>
        <ButtonCancel
          :clickHandler="cancel"
          title="remover arquivo"
          height="1vh"
          class="me-2"
          v-if="percent != 100"
        ></ButtonCancel>
        <br />
      </div>
      <div class="col-8 ps-2">
        <div class="row">
          <div class="col text-start text-break">
            <span style="font-size: 0.9em">
              {{ nome }}
              <br />
              <ProgressBar :percent="percent"></ProgressBar>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconCsv from "./IconCsv.vue";
import ProgressBar from "./ProgressBar.vue";
import ButtonPlay from "./ButtonPlay.vue";
import ButtonPause from "./ButtonPause.vue";
import ButtonCancel from "./ButtonCancel.vue";

export default {
  props: {
    nome: { type: String, default: "" },
    uploading: { type: Boolean, default: false },
    percent: { type: String },
    playHandler: { type: Function, default: () => {} },
    pauseHandler: { type: Function, default: () => {} },
    cancelHandler: { type: Function, default: () => {} },
  },
  data() {
    return {};
  },
  components: {
    IconCsv,
    ProgressBar,
    ButtonPlay,
    ButtonPause,
    ButtonCancel,
  },
  methods: {
    play() {
      this.playHandler();
    },
    pause() {
      this.pauseHandler();
    },
    cancel() {
      this.cancelHandler();
    },
  },
};
</script>
<style></style>
