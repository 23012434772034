<template>
  <div>
    <input type="file" id="fileElem" :multiple="multiple" :accept="this.acceptExt" style="display: none"
      @change="adicionaArquivos" />
    <div :class="{
      alert: true,
      'alert-light': true,
      'text-center': true,
      'tus-client': true,
      disabled: disabled,
      readonly: readonly,
    }">
      <div class="p-1">
        Carregue e solte um arquivo ou clique na área abaixo para fazer upload.
      </div>
      <div id="drag-area-hidden" @click="abreFileDialog" @dragover="dragoverHandler" @drop="soltaArquivos"
        @dragenter="dragenterHandler" @dragexit="dragexitHandler" @dragend="dragendHandler"
        style="background-color: rgba(0,0,0, 0); z-index=10">
        <div id="drag-area" :class="{ 'drag-enter': dragging, 'drop-area': true, 'p-4': true }">
          <!-- mostra drop area vazia, se nao foram carregados arquivos -->
          <div v-show="uploadsLen == 0 && !dragging">
            <IconUpload title="carregue arquivos aqui" :disabled="disabled" :clickHandler="abreFileDialog"></IconUpload>
          </div>
          <!-- lista arquivos, se algum foi carregado -->
          <div v-if="uploadsLen > 0" class="row row-cols-1">
            <div class="col" v-for="(upload, key) in tusUploads" :key="key">
              <BlocoArquivo v-if="upload" :nome="upload?.options?.metadata?.filename"
                :percent="tusUploadsPercentages[key]" :uploading="tusUploadsUploading[key]"
                :play-handler="() => startOrResumeUpload(key)" :pause-handler="() => pauseUpload(key)"
                :cancel-handler="() => removeArquivo(key)"></BlocoArquivo>
              <ArquivoRepetidoDialog :indice="key" :nome-arquivo="upload?.options?.metadata?.filename"
                :callback-substituir="() => mdlSubstituir(key)">
              </ArquivoRepetidoDialog>
            </div>
          </div>
          <!-- mostra drop area durante o drag -->
          <div v-show="dragging" class="dragging">
            <div class="row pt-1">
              <div class="col">
                <IconDrop title="solte arquivos aqui"></IconDrop>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div>solte o arquivo aqui</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col" v-if="uploadingLen <= 0">
          <div class="d-grid">
            <button :class="{
              btn: true,
              'btn-success': true,
              disabled: disabled || disabledEnviar,
            }" @click="enviar">
              Enviar
            </button>
          </div>
        </div>
        <div class="col" v-else>
          <div class="d-grid">
            <button :class="{ btn: true, 'btn-danger': true, disabled: disabled }" @click="pausar">
              Pausar
            </button>
          </div>
        </div>
        <div class="col">
          <div class="d-grid">
            <button :class="{
              btn: true,
              'btn-secondary': true,
              disabled: disabled,
            }" @click="reset">
              Limpar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as tus from "tus-js-client";
import { validate as uuidValidate } from "uuid";
import IconUpload from "./IconUpload.vue";
import IconDrop from "./IconDrop.vue";
import BlocoArquivo from "./BlocoArquivo.vue";
import ArquivoRepetidoDialog from "./ArquivoRepetidoDialog.vue";

export default {
  components: {
    IconUpload,
    IconDrop,
    BlocoArquivo,
    ArquivoRepetidoDialog,
  },
  props: {
    endpointTus: { type: String, required: true },
    chunkSize: { type: Number, default: undefined },
    token: { type: String, default: "" },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledEnviar: { type: Boolean, default: false },
    allowedExtensions: { type: Array, default: () => ["csv"] },
  },
  data() {
    return {
      acceptExt: "",
      error: null,
      dragging: false,
      uploadsLen: 0,
      uploadingLen: 0,
      tusUploads: [],
      tusUploadsPercentages: [],
      tusUploadsUploading: [],
    };
  },
  watch: {
    uploadsLen() {
      if (this.uploadsLen <= 0) {
        this.tusUploads = [];
        this.tusUploadsUploading = [];
        this.tusUploadsPercentages = [];
      }
    },
  },
  computed: {
    readonly() {
      return this.uploadsLen > 0;
    },
    versao() {
      return this.$store.getters.const.sistema.versao || "";
    },
  },
  methods: {
    enviar() {
      if (this.disabled) return false;
      this.$emit(`send-uploads`);
      for (let i = 0; i < this.tusUploads.length; i++) {
        const r = this.startOrResumeUpload(i);
        if (r) {
          this.$set(this.tusUploadsUploading, i, true);
          this.$set(this.tusUploadsPercentages, i, "0");
        }
      }
    },
    pausar() {
      if (this.disabled) return false;
      this.$emit(`pause-uploads`);
      for (let i = 0; i < this.tusUploads.length; i++) {
        this.pauseUpload(i);
        this.$set(this.tusUploadsUploading, i, false);
      }
      this.uploadingLen = 0;
    },
    reset() {
      if (this.disabled) return false;
      this.$emit(`reset-uploads`);
      for (let i = 0; i < this.tusUploads.length; i++) {
        this.pauseUpload(i, false);
        this.$set(this.tusUploadsUploading, i, false);
      }
      document.getElementById("fileElem").value = "";
      this.tusUploads = [];
      this.dragexitHandler();
      this.dragging = false;
      this.uploadsLen = 0;
      this.uploadingLen = 0;
    },
    abreFileDialog(ev) {
      if (this.disabled) return false;
      if (this.readonly) return false;
      ev.preventDefault();
      document.getElementById("fileElem").click();
    },
    adicionaArquivos(ev) {
      if (this.disabled) return false;
      if (this.readonly) return false;
      ev.preventDefault();
      const fileElem = document.getElementById("fileElem");
      for (let i = 0; i < fileElem?.files.length; i++) {
        this.createTusUpload(fileElem?.files[i], i);
      }
    },
    dragoverHandler(ev) {
      if (this.disabled) return false;
      if (this.readonly) return false;
      ev.preventDefault();
      this.dragging = true;
    },
    dragenterHandler(ev) {
      if (this.disabled) return false;
      if (this.readonly) return false;
      ev.preventDefault();
      this.dragging = true;
    },
    dragexitHandler() {
      if (this.disabled) return false;
      if (this.readonly) return false;
      this.dragging = false;
    },
    dragendHandler(ev) {
      if (this.disabled) return false;
      if (this.readonly) return false;
      ev.preventDefault();
      this.dragging = false;
    },
    soltaArquivos(ev) {
      if (this.disabled) return false;
      if (this.readonly) return false;
      ev.preventDefault();
      this.dragging = false;
      if (ev.dataTransfer.items) {
        let i = 0;
        [...ev.dataTransfer.items].forEach((item) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            const file = item.getAsFile();
            this.createTusUpload(file, i);
            i++;
          }
        });
      } else {
        let i = 0;
        [...ev.dataTransfer.files].forEach((file) => {
          this.createTusUpload(file, i);
          i++;
        });
      }
    },
    getUuid(upload) {
      let url = upload.url;
      let uuid = null;
      if (url) {
        let uuidArr = url.split("/");
        uuid = uuidArr[uuidArr.length - 1];
      }
      if (uuidValidate(uuid)) {
        return uuid;
      }
      return false;
    },
    createTusUpload(file, indice) {
      const upload = new tus.Upload(file, {
        endpoint: this.endpointTus,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        overridePatchMethod: true,
        // chunkSize: this.chunkSize,
        metadata: {
          id: indice,
          filename: file.name,
          filetype: file.type,
        },
        headers: {
          "X-Client-Version": this.versao,
          "Access-Control-Allow-Headers": "X-Client-Version",
          Authorization: `Bearer ${this.$store.getters.token}`,
        },

        onError: (error) => {
          console.log("Failed because: " + error);
          let uuid = this.getUuid(upload);
          this.$emit(`error-upload`, { upload, index: indice, tusId: uuid });
        },
        onProgress: async (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          this.$set(this.tusUploadsPercentages, indice, percentage);
          let uuid = this.getUuid(upload);
          this.$emit(`progress-upload`, { upload, index: indice, tusId: uuid });
        },
        onSuccess: async () => {
          this.uploadingLen--;
          this.$set(this.tusUploadsUploading, indice, false);
          let uuid = this.getUuid(upload);
          this.$emit(`success-upload`, { upload, index: indice, tusId: uuid });
        },
      });

      this.uploadsLen++;
      this.tusUploads.push(upload);
      this.tusUploadsUploading.push(false);
      this.tusUploadsPercentages.push("0");
      this.$set(this.tusUploadsUploading, indice, false);
    },
    startOrResumeUpload(indice) {
      const upload = this.tusUploads[indice];
      if (upload) {
        const filenameArr = upload.options.metadata.filename.split(".");
        const filenameExt = filenameArr[filenameArr.length - 1];
        if (this.allowedExtensions.includes(filenameExt)) {
          this.$emit(`start-upload`, { upload, index: indice });
          upload.findPreviousUploads().then(async (previousUploads) => {
            if (previousUploads.length) {
              return await this.$bvModal.show("modal-confirm-file-" + indice);
            } else {
              upload.start();
              return true;
            }
          });
        } else {
          this.$emit(`error-upload`, {
            upload,
            index: indice,
            msg: "Extensão não permitida",
          });
        }
      }
    },
    async mdlSubstituir(indice) {
      this.uploadingLen++;
      this.$set(this.tusUploadsUploading, indice, true);
      const upload = this.tusUploads[indice];
      if (upload) {
        this.$emit(`start-upload-substituir`, { upload, index: indice });
        upload.start();
        await this.$bvModal.hide("modal-confirm-file-" + indice);
      }
    },
    pauseUpload(indice, emitEvent = true) {
      this.uploadingLen--;
      this.$set(this.tusUploadsUploading, indice, false);
      const upload = this.tusUploads[indice];
      if (upload) {
        let uuid = this.getUuid(upload);
        if (emitEvent)
          this.$emit(`pause-upload`, { upload, index: indice, tusId: uuid });
        upload.abort();
      }
    },
    removeArquivo(indice) {
      this.uploadsLen--;
      this.pauseUpload(indice, false);
      this.$emit(`remove-upload`, { index: indice });
      document.getElementById("fileElem").value = "";
      delete this.tusUploads[indice];
      delete this.tusUploadsUploading[indice];
      delete this.tusUploadsPercentages[indice];
    },
  },
  async mounted() {
    const allowedExt = this.allowedExtensions.map((x) => `.${x}`);
    this.acceptExt = allowedExt.join(",");
  },
};
</script>

<style scoped>
.tus-client {
  min-height: 10em;
}

.drop-area {
  margin: 1em;
  min-height: 6em;
  min-width: 90%;
  border: 2px dashed gray;
  background-color: rgba(255, 255, 255, 0.3);
}

.drag-enter {
  margin: 1em;
  min-height: 6em;
  min-width: 90%;
  border: 2px dashed white;
  background-color: rgba(70, 70, 70, 0.2);
}

.dragging {
  color: white;
  font-weight: 900;
  padding-top: 1em;
}

.disabled {
  min-height: 100%;
  cursor: not-allowed;
  z-index: 1000 !important;
  background-color: rgba(100, 100, 100, 0.3) !important;
}

.readonly {
  min-height: 100%;
  cursor: no-drop;
  z-index: 10 !important;
  background-color: rgba(100, 200, 255, 0.05) !important;
}
</style>
