<template>
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :alt="title"
      :title="title"
      :height="height"
      @click.stop="clickHandler"
      @mouseover.stop="isHovering = true"
      @mouseout.stop="isHovering = false"
      :class="{ hovering: isHovering && !disabled, disabled: disabled }"
      :viewBox="viewBox"
    >
      <path :d="path" />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    clickHandler: { type: Function, default: () => {} },
    path: { type: String, required: true },
    viewBox: { type: String, required: true },
    height: { type: String, default: "2em" },
    title: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      isHovering: false,
    };
  },
};
</script>

<style scoped>
.hovering {
  fill: darkgray;
}
.disabled {
  fill: lightgray;
}
</style>
