<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">Gerenciamento de beneficiários</div>
          <div class="card-body p-3">
            <busca-beneficiarios ref="busca"></busca-beneficiarios>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1 row">
      <resultado-busca
        :mesAnoReferencia="mes_ano_referencia"
        @familia-inativada="$refs['busca'].buscar()"
      ></resultado-busca>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "resultado-busca": require("../components/administracao/ResultadoBusca.vue")
      .default,
    "busca-beneficiarios":
      require("../components/administracao/BuscaBeneficiarios.vue").default,
  },
  data() {
    return {
      items: [
        { text: "Admin" },
        { text: "Gerenciamento de beneficiários", active: true },
      ],
      mes_ano_referencia: null,
    };
  },
  computed: {
    http() {
      return this.$store.getters.http;
    },
  },
  methods: {
    async buscarParams() {
      try {
        let { data } = await this.http.get(`/admin/params`, {
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        });
        this.mes_ano_referencia = data.dados.mes + "/" + data.dados.ano;
      } catch (error) {
        this.error = error;
      }
    },
  },

  mounted() {
    this.buscarParams();
  },
};
</script>

