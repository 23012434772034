<template>
  <span>
    <button
      @click.stop="clickHandler"
      :title="title"
      @mouseover.stop="isHovering = true"
      @mouseout.stop="isHovering = false"
      :class="{
        disabled: disabled,
        btn: true,
        'btn-light': true,
        'btn-sm': true,
      }"
    >
      <Icon
        :clickHandler="clickHandler"
        :height="height"
        :title="title"
        :viewBox="viewBox"
        :path="path"
        :disabled="disabled"
      ></Icon>
      <span v-if="text?.length > 0" class="btn-txt">{{ text }}</span>
    </button>
  </span>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  props: {
    clickHandler: { type: Function, default: () => {} },
    height: { type: String, default: "2em" },
    title: { type: String, required: true },
    text: { type: String, required: false },
    path: { type: String, required: true },
    viewBox: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  components: {
    Icon,
  },
  data() {
    return {
      isHovering: false,
    };
  },
};
</script>

<style scoped>
.hovering {
  fill: white;
}
.btn-txt {
  margin-left: 1em;
  font-size: smaller;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

@media screen and (max-width: 800px) {
  .btn-txt {
    display: none;
  }
}
</style>
