<template>
  <div>
    <div id="toastfeedback"
      :class="{ 'toast': true, 'bg-success-subtle': !!toastSuccess, 'bg-danger-subtle': !toastSuccess, 'position-fixed': true, 'bottom-0': true, 'end-0': true, 'p-1': true, 'mb-5': true, 'me-2': true }"
      style="width: 20em; z-index: 200" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div :class="{ 'toast-body': true }">{{ toastMsg }}</div>
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
    <!-- ============== input de filtro ==================== -->
    <b-row class="mt-1 pb-2 align-items-center" v-if="qtd > 0">
      <b-col cols="2">
        <select class="form-select" v-model="perPageModel">
          <option value="10" :selected="perPageModel == '10'">10</option>
          <option value="25" :selected="perPageModel == '25'">25</option>
          <option value="50" :selected="perPageModel == '50'">50</option>
        </select>
      </b-col>
      <b-col cols="2">
        <span style="font-size: smaller">registros por página</span>
      </b-col>
      <b-col>
        <b-input-group size="sm">
          <b-form-input v-model="filter" type="text" placeholder="Filtre aqui o resultado da pesquisa"
            @keyup.esc="filter = ''"></b-form-input>
          <b-input-group-append>
            <a class="btn btn-outline-warning" title="Limpar" :disabled="!filter" @click="filter = ''">
              <font-awesome-icon :icon="['fas', 'times']" />
            </a>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <!-- ==================== tabela ==================== -->
    <b-table hover small caption-top :items="itens" :fields="fields" stacked="md" primary-key="cpf"
      :current-page="currPage" :busy="loading" :per-page="perPageModel" :filter="filter" :filterIncludedFields="['nome']"
      @filtered="onFiltered">

      <!-- ----------------- cpf (filtro) ------------------------- -->
      <template v-slot:cell(cpf)="row">
        <span style="font-size: .9em">{{ row.item.cpf | formataCpf }}</span>
      </template>

      <!-- ----------------- tipo_produto (editavel) ----------------------- -->
      <template v-slot:cell(tipo_produto)="row"><span style="font-size: .9em">{{ descricaoTipoCesta(row.item.tipo_produto)
      }}</span>
      </template>

      <!-- ------------------ acoes -------------------------------- -->
      <template v-slot:cell(acoes)="row">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" :id="'retirado' + row.item.id"
            :checked="row.item.flag_voucher_retirado === 'true'" @change.prevent="toggleFlagRetirado($event, row.item)">
          <label class="form-check-label" style="font-size: .9em" :for="'retirado' + row.item.id">{{
            row.item.flag_voucher_retirado === 'true' ?
            "Sim" : "Não" }}</label>
        </div>
      </template>
    </b-table>

    <!-- ==================== paginacao ==================== -->
    <b-pagination v-model="currPage" :total-rows="qtd" :per-page="perPageModel" size="sm" class="mt-1" number-of-pages="5"
      align="right" v-if="qtd > 0"></b-pagination>

  </div>
</template>
<script>
import { Toast } from "bootstrap";
import helpers from '../../../helpers/index'

export default {
  props: {
    editable: { type: Boolean, default: false },
    value: { type: Array, default: () => [{}] },
    paginate: { type: Boolean, default: true },
    perPage: { type: Number, default: 10 },
  },
  data() {
    return {
      currPage: 1,
      perPageModel: 0,
      totalRows: null,
      benes: [],
      beneCurr: null,
      loading: false,
      filter: "",
      justificativa: "",
      tipo_produto: null,
      toastMsg: '',
      toastSuccess: false,
    };
  },
  computed: {
    http() {
      return this.$store.getters.http;
    },
    qtd() {
      return this.totalRows || this.benes.length;
    },
    itens() {
      return this.benes;
    },
    fields() {
      return [
        { key: "cpf", label: "CPF", sortable: true, class: "text-left" },
        { key: "nome", label: "Nome", class: "text-left" },
        { key: "tipo_produto", label: "Tipo Cesta", class: "text-left" },
        { key: "acoes", label: "Retirada?", class: "text-center" },
      ];
    },
  },
  watch: {
    value() {
      this.benes = [...this.value];
    },
  },
  methods: {
    descricaoTipoCesta(tipo) {
      return helpers.getDescricaoTipoCesta(tipo);
    },
    async toggleFlagRetirado(e, item) {
      e.preventDefault();
      this.$store.commit("setLoading", true);
      this.loading = true;
      try {
        let { data } = await this.http.patch(
          `/admin/edit-retirado/${item.id}`,
          {
            flag_voucher_retirado: item.flag_voucher_retirado,
            _method: "patch",
          },
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
          }
        );
        if (data.erro === "true") {
          this.error = data.erro.message;
          this.toast(data.erro.message, "danger", "Ops!");
          document.getElementById(`retirado${item.id}`).checked = (item.flag_voucher_retirado === 'true');
        } else {
          console.log(item);
          item.flag_voucher_retirado = item.flag_voucher_retirado === "true" ? "false" : "true"
          this.toast(data.message);
        }
        this.$emit("input", this.benes);
        this.loading = false;
        this.$store.commit("setLoading", false);
      } catch (e) {
        document.getElementById(`retirado${item.id}`).checked = (item.flag_voucher_retirado === 'true');
        this.loading = false;
        this.$store.commit("setLoading", false);
        this.toast(e.message, "danger", "Ops!");
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currPage = 1;
    },
    toast(msg, variant = "success", title = "Sucesso!") {
      this.toastMsg = msg;
      this.toastSuccess = variant == 'success';
      let e = document.getElementById("toastfeedback");
      let t = Toast.getOrCreateInstance(e, { delay: 3000, appendToast: false });
      t['_config'].title = title;
      t.show();
    },
  },
  mounted() {
    this.benes = [...this.value];
    this.perPageModel = this.perPage;
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5 !important;
}

.toast {
  z-index: 2000 !important;
}
</style>
