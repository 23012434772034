<template>
  <div>
    <div v-if="edit && editable" class="input-group input-group-sm">
      <b-form-input type="text" name="nome" id="nome" :class="`form-control` + ($v.nome.$error ? ` is-invalid` : ``)"
        v-model.trim="$v.nome.$model" @change="$v.nome.$touch()" @keyup.enter="salvar(beneficiario)"
        @keyup.esc="cancelar(beneficiario)" @keyup.native="removeAcentos($event)" />
      <div class="input-group-append">
        <button :disabled="disabled" class="btn btn-success" @click="salvar(beneficiario)" title="Salvar">
          <font-awesome-icon :icon="['fas', 'check']" />
        </button>
      </div>
      <div class="input-group-append">
        <button :disabled="disabled" class="btn btn-secondary" @click="$emit('cancelar', beneficiario)" title="Cancelar">
          <font-awesome-icon :icon="['fas', 'times']" />
        </button>
      </div>
    </div>
    <span v-else>{{ beneficiario.nome }}</span>
    <div class="text-danger" v-if="edit && $v.nome.$error">
      <div v-if="!$v.nome.required">
        <small>Este campo é obrigatório.</small>
      </div>
      <div v-if="!$v.nome.minLength">
        <small>Este campo deve possuir pelo menos 3 letras.</small>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  props: {
    beneficiario: { type: Object, default: () => { } },
    disabled: { type: Boolean, default: false },
    edit: { type: Boolean, default: false }, // modo edicao, alterado com o clique do botao
    editable: { type: Boolean, default: true }, // para casos em que nao eh permitido editar, p.ex. de acordo com o perfil
  },
  data() {
    return {
      nome: "",
    };
  },
  validations: {
    nome: {
      required,
      minLength: minLength(3),
    },
  },
  watch: {
    edit() {
      if (!this.edit) this.nome = this.beneficiario.nome;
    },
  },
  methods: {
    salvar(beneficiario) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let newBene = { ...beneficiario };
        newBene.nome = this.nome;
        this.$emit("salvar", newBene);
      }
    },
    cancelar(beneficiario) {
      this.nome = this.beneficiario.nome;
      this.$emit("cancelar", beneficiario);
    },
    removeAcentos(e) {
      var texto = e.target.value;
      var semAcento = texto
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase();
      e.target.value = semAcento;
    },
  },
  mounted() {
    this.nome = this.beneficiario.nome;
  },
};
</script>

<style></style>