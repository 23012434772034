<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">Gerenciamento de parâmetros</div>
          <div class="card-body p-3">
            <div class="alert alert-success" v-if="sucesso">{{ sucesso }}</div>
            <div class="alert alert-warning" v-if="error">{{ error }}</div>
            <div class="row mb-2">
              <div class="col-6 col-md-3 font-weight-bold">
                Mês de referência:
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-4">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="mes"
                    >
                      <option
                        :value="m.id"
                        v-for="(m, i) in meses"
                        :key="i"
                        :selected="m.id == mes"
                      >
                        {{ m.desc }}
                      </option>
                    </select>
                  </div>
                  <div class="col-1">/</div>
                  <div class="col-4">
                    <input type="number" class="form-control" v-model="ano" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6 col-md-3 font-weight-bold">
                Data inicial de validade do voucher:
              </div>

              <div class="col-5 col-md-8">
                <the-mask
                  id="datainicialvoucher"
                  type="text"
                  :class="
                    `form-control` +
                    ($v.datainicialvoucher.$error ? ` is-invalid` : ``)
                  "
                  name="datainicialvoucher"
                  placeholder="dd/mm/aaaa"
                  v-model.trim="$v.datainicialvoucher.$model"
                  @change="$v.datainicialvoucher.$touch()"
                  mask="##/##/####"
                  :masked="true"
                  @keyup.enter="entrar"
                ></the-mask>
                <div class="error" v-if="$v.datainicialvoucher.$error">
                  <div v-if="!$v.datainicialvoucher.required">
                    <small>Este campo é obrigatório.</small>
                  </div>
                  <div
                    v-if="
                      !$v.datainicialvoucher.minLength ||
                      !$v.datainicialvoucher.date
                    "
                  >
                    <small>Data inválida.</small>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <a
                  href="#"
                  class="py-2 text-info"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Data inicial de validade do voucher."
                >
                  <font-awesome-icon :icon="['fas', 'fa-question-circle']" />
                </a>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6 col-md-3 font-weight-bold">
                Data final de validade do voucher:
              </div>

              <div class="col-5 col-md-8">
                <the-mask
                  id="datafinalvoucher"
                  type="text"
                  :class="
                    `form-control` +
                    ($v.datafinalvoucher.$error ? ` is-invalid` : ``)
                  "
                  name="datafinalvoucher"
                  placeholder="dd/mm/aaaa"
                  v-model.trim="$v.datafinalvoucher.$model"
                  @change="$v.datafinalvoucher.$touch()"
                  mask="##/##/####"
                  :masked="true"
                  @keyup.enter="entrar"
                ></the-mask>
                <div class="error" v-if="$v.datafinalvoucher.$error">
                  <div v-if="!$v.datafinalvoucher.required">
                    <small>Este campo é obrigatório.</small>
                  </div>
                  <div
                    v-if="
                      !$v.datafinalvoucher.minLength ||
                      !$v.datafinalvoucher.date
                    "
                  >
                    <small>Data inválida.</small>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <a
                  href="#"
                  class="py-2 text-info"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Data final de validade do voucher."
                >
                  <font-awesome-icon :icon="['fas', 'fa-question-circle']" />
                </a>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6 col-md-3 font-weight-bold">
                Data de fechamento para edição:
              </div>

              <div class="col-5 col-md-8">
                <the-mask
                  id="datafinaledicao"
                  type="text"
                  :class="
                    `form-control` +
                    ($v.datafinaledicao.$error ? ` is-invalid` : ``)
                  "
                  name="datafinaledicao"
                  placeholder="dd/mm/aaaa"
                  v-model.trim="$v.datafinaledicao.$model"
                  @change="$v.datafinaledicao.$touch()"
                  mask="##/##/####"
                  :masked="true"
                  @keyup.enter="entrar"
                ></the-mask>
                <div class="error" v-if="$v.datafinaledicao.$error">
                  <div v-if="!$v.datafinaledicao.required">
                    <small>Este campo é obrigatório.</small>
                  </div>
                  <div
                    v-if="
                      !$v.datafinaledicao.minLength || !$v.datafinaledicao.date
                    "
                  >
                    <small>Data inválida.</small>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <a
                  href="#"
                  class="py-2 text-info"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Data de fechamento para edição da família."
                >
                  <font-awesome-icon :icon="['fas', 'fa-question-circle']" />
                </a>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6 col-md-3 font-weight-bold">
                Modo manutenção:
              </div>

              <div class="col-5 col-md-8">
                <b-form-checkbox
                  v-model="modoManutencao"
                  name="check-button"
                  switch
                >
                  <b class="ps-2">{{
                    modoManutencao ? "HABILITADO" : "DESABILITADO"
                  }}</b>
                </b-form-checkbox>
              </div>
              <div class="col-1">
                <a
                  href="#"
                  class="py-2 text-info"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Se HABILITADO, tira o sistema do ar para todos usuários (exceto
                o SUPERADMINISTRADOR)."
                >
                  <font-awesome-icon :icon="['fas', 'fa-question-circle']" />
                </a>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col">
                <div class="d-grid">
                  <button class="btn btn-success" @click="salvar">
                    Salvar
                  </button>
                </div>
              </div>
              <div class="col">
                <div class="d-grid">
                  <button class="btn btn-secondary" @click="cancelar">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validaData } from "../validations/index";
import { TheMask } from "vue-the-mask";
import { required, minLength } from "vuelidate/lib/validators";
import { Tooltip } from "bootstrap";

export default {
  components: { TheMask },
  data() {
    return {
      mes: "",
      ano: "",
      datafinaledicao: "",
      datainicialvoucher: "",
      datafinalvoucher: "",
      meses: [
        { id: "", desc: "Selecione" },
        { id: "1", desc: "janeiro" },
        { id: "2", desc: "fevereiro" },
        { id: "3", desc: "março" },
        { id: "4", desc: "abril" },
        { id: "5", desc: "maio" },
        { id: "6", desc: "junho" },
        { id: "7", desc: "julho" },
        { id: "8", desc: "agosto" },
        { id: "9", desc: "setembro" },
        { id: "10", desc: "outubro" },
        { id: "11", desc: "novembro" },
        { id: "12", desc: "dezembro" },
      ],
      parametros: {
        mes: "",
        ano: "",
        datafinaledicao: "",
        datainicialvoucher: "",
        datafinalvoucher: "",
      },
      parametrosOriginal: "",
      modoManutencao: false,

      items: [
        { text: "Lista Parâmetros", to: { name: "lista_params" } },
        { text: "Parâmetros", active: true },
      ],

      error: null,
      sucesso: null,
    };
  },
  validations: {
    datafinaledicao: {
      required,
      data: (strData) => validaData(strData),
      minLength: minLength(10),
    },
    datainicialvoucher: {
      required,
      data: (strData) => validaData(strData),
      minLength: minLength(10),
    },
    datafinalvoucher: {
      required,
      data: (strData) => validaData(strData),
      minLength: minLength(10),
    },
  },

  computed: {
    http() {
      return this.$store.getters.http;
    },
  },
  methods: {
    cancelar() {
      this.parametros = this.parametrosOriginal;
      this.mes = this.parametrosOriginal.mes;
      this.ano = this.parametrosOriginal.ano;
      this.datafinaledicao = this.parametrosOriginal.datafinaledicao;
      this.datainicialvoucher = this.parametrosOriginal.datainicialvoucher;
      this.datafinalvoucher = this.parametrosOriginal.datafinalvoucher;
      this.error = null;
      this.sucesso = null;
      this.$v.$reset();
    },
    async salvar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$Progress.start();
        let dataFinalEdicao = this.datafinaledicao.replace(/\//g, "-");
        let dataInicialVoucher = this.datainicialvoucher.replace(/\//g, "-");
        let dataFinalVoucher = this.datafinalvoucher.replace(/\//g, "-");
        try {
          let { data } = await this.http.patch(
            `/admin/params/${this.parametros.id}`,
            {
              mes: this.mes,
              ano: this.ano,
              datafinaledicao: dataFinalEdicao,
              datainicialvoucher: dataInicialVoucher,
              datafinalvoucher: dataFinalVoucher,
              manutencao: this.modoManutencao,
              _method: "patch",
            },
            {
              headers: { Authorization: `Bearer ${this.$store.getters.token}` },
            }
          );
          if (data.erro === "true") {
            this.error = data.message;
          } else {
            this.parametros.id = data.dados.id;
            this.parametrosOriginal.mes = this.mes;
            this.parametrosOriginal.ano = this.ano;
            this.parametrosOriginal.datafinaledicao = this.datafinaledicao;
            this.parametrosOriginal.datainicialvoucher =
              this.datainicialvoucher;
            this.parametrosOriginal.datafinalvoucher = this.datafinalvoucher;
            this.sucesso = "Parâmetros salvos com sucesso!";
          }
          this.$store.commit("setLoading", false);

          this.$Progress.finish();
        } catch (error) {
          this.error = error;
          this.$store.commit("setLoading", false);
          this.$Progress.fail();
        }
      }
    },
    async buscar() {
      this.$Progress.start();
      try {
        let data_0 = await this.http.get(`/manutencao`);
        let { data } = await this.http.get(`/admin/params`, {
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        });

        if (data_0.data.erro === "true") {
          this.error = data_0.data.message;
        } else {
          this.modoManutencao = data_0.data.dados.flag_manutencao === "1";
        }
        if (data.erro === "true") {
          this.error = data.message;
        } else {
          this.parametros = data.dados;
          this.mes = data.dados.mes;
          this.ano = data.dados.ano;
          this.datafinaledicao = data.dados.datafinaledicao;
          this.datainicialvoucher = data.dados.datainicialvoucher;
          this.datafinalvoucher = data.dados.datafinalvoucher;
          this.parametrosOriginal = data.dados;
        }
        this.$store.commit("setLoading", false);
        this.$Progress.finish();
      } catch (error) {
        this.$Progress.fail();
        this.error = error;
        this.$store.commit("setLoading", false);
      }
    },
  },
  mounted() {
    this.buscar();
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    // eslint-disable-next-line no-unused-vars
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  },
};
</script>

<style scoped>
.error {
  color: #dc3545;
  text-align: right;
}
</style>
