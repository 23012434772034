<template>
  <div class="mt-5 row">
    <div class="mx-auto col-lg-9 col-xs-12">
      <b-breadcrumb :items="items"></b-breadcrumb>
      <b-alert :variant="variantAlert" :show="showAlert">
        <font-awesome-icon
          v-if="!iconCheck"
          :icon="['fas', 'fa-exclamation-triangle']"
        />
        <font-awesome-icon v-else :icon="['fas', 'fa-check']" />
        {{ msgAlert }}</b-alert
      >

      <div class="mb-3"></div>
      <div class="accordion">
        <div class="accordion-item">
          <div class="accordion-header">
            <button
              :class="{ 'accordion-button': true, collapsed: uploaded }"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelImport"
              aria-expanded="true"
              aria-controls="panelImport"
            >
              Importação
            </button>
          </div>
          <div
            id="panelImport"
            :class="{
              'accordion-collapse': true,
              collapse: true,
              show: !uploaded,
            }"
          >
            <div class="accordion-body">
              <b-row>
                <b-col>
                  <strong style="font-size: smaller"
                    >⚠️ Somente permitido arquivos com até 10.000
                    registros.</strong
                  >
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <strong style="font-size: smaller"
                    >⚠️ Evite repetir nomes de arquivos já enviados e importados.</strong
                  >
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <label class="col-md-1 p-1" for="arquivo">Arquivo</label>
                  <b-form-file
                    id="arquivo"
                    :disabled="flagImportRetorno"
                    @input="tamanhoArquivoCSV()"
                    v-model="arquivo"
                    :accept="extPermitidasArquivo.toString()"
                    placeholder="Clique aqui para inserir um arquivo"
                  ></b-form-file>
                </b-col>
              </b-row>

              <div align="right" class="p-0 col-12">
                <b-button
                  @click="importar"
                  :disabled="!arquivo || flagImportRetorno"
                  variant="primary"
                >
                  <font-awesome-icon
                    :icon="['fas', 'file-import']"
                    title="Importar Arquivo"
                  />
                  Importar
                </b-button>
              </div>
              <div v-if="flagImportRetorno" class="mt-3">
                <label class="mb-1">
                  <font-awesome-icon
                    class="text-warning"
                    :icon="['fas', 'fa-exclamation-triangle']"
                  />
                  <strong>&nbsp;{{ textImportacao }}</strong></label
                >
                <b-progress variant="warning" class="mb-3" :max="max">
                  <b-progress-bar
                    class="text-dark"
                    style="font-weight: bold"
                    :value="value"
                    :label="`${((value / max) * 100).toFixed(0)}%`"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-header">
            <button
              :class="{ 'accordion-button': true, collapsed: !uploadedResult }"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelResultado"
              aria-expanded="true"
              aria-controls="panelResultado"
            >
              Resultado da importação
            </button>
            <div
              id="panelResultado"
              :class="{
                'accordion-collapse': true,
                collapse: true,
                show: uploadedResult,
              }"
            >
              <div class="accordion-body">
                <b-row class="mb-4">
                  <b-col align="center" style="font-size: smaller">
                    <strong>Arquivo: </strong>{{ this.nomeArquivo }}
                  </b-col>
                  <b-col align="center" style="font-size: smaller">
                    <strong>Data da importação: </strong
                    >{{ this.dataImportacao }}
                  </b-col>
                  <b-col align="right">
                    <b-button
                      :disabled="totaisRegistros.erros == 0"
                      variant="primary"
                      title="Gerar Relatório de Erro"
                      @click="gerarRelatorio()"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'file-excel']"
                        title="Gerar Relatório de Erro"
                      />
                      Gerar Relatório de Erros
                    </b-button>
                  </b-col>
                </b-row>

                <b-row class="justify-content-md-center mx-0 mb-4">
                  <b-col>
                    <b-list-group horizontal>
                      <b-list-group-item
                        >Total de registros:
                        <b-badge variant="primary">{{
                          totaisRegistros.numeroRegistros
                        }}</b-badge>
                      </b-list-group-item>
                      <b-list-group-item
                        >Registros importados com sucesso:
                        <b-badge variant="success">{{
                          totaisRegistros.importadosComSucesso
                        }}</b-badge>
                      </b-list-group-item>
                      <b-list-group-item
                        >Registros com erro:
                        <b-badge variant="danger">{{
                          totaisRegistros.erros
                        }}</b-badge>
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from "../helpers";
import moment from "moment";

export default {
  data() {
    return {
      items: [
        { text: "Admin", to: { name: "admin" } },
        { text: "Importação de dados - Mercado", active: true },
      ],
      arquivo: null,
      nomeArquivo: "",
      dataImportacao: null,
      extPermitidasArquivo: [".txt", ".csv"],
      uploaded: false,
      uploadedResult: false,
      totaisRegistros: {
        numeroRegistros: 0,
        importadosComSucesso: 0,
        erros: 0,
      },
      variantAlert: "",
      msgAlert: "",
      showAlert: false,
      iconCheck: false,
      value: 0,
      max: 0,
      textImportacao: "",
      flagImportRetorno: false,
      qtdRegistrosArquivo: null,
    };
  },

  computed: {
    http() {
      return this.$store.getters.http;
    },
  },

  methods: {
    tamanhoArquivoCSV() {
      helpers
        .convertCSVToArray(this.arquivo)
        .then((response) => {
          this.qtdRegistrosArquivo = response.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getFormDataArquivo(arquivo, idImportacao) {
      const formData = new FormData();
      formData.append("id_importacao", idImportacao);
      formData.append("arquivo", arquivo);
      formData.append("nome_arquivo", this.arquivo.name);
      return formData;
    },

    async importar() {
      try {
        if (this.qtdRegistrosArquivo > 10001) {
          this.showAlert = true;
          this.msgAlert = "Arquivo selecionado tem mais que 10.000 registros.";
          this.variantAlert = "warning";
          return;
        }
        this.uploadedResult = false;
        this.showAlert = false;
        this.msgAlert = "";
        this.$store.commit("setLoading", true);
        this.$store.commit("setFlagMsgLoading", true);
        this.$store.commit(
          "setMsgLoading",
          "Carregando e montando os dados do arquivo antes de importar...aguarde!"
        );
        let idImportacao = Math.random();
        let params = this.getFormDataArquivo(this.arquivo, idImportacao);
        let { data } = await this.http.post(`/admin/import/mercado`, params, {
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        });
        this.$store.commit("setLoading", false);
        this.$store.commit("setFlagMsgLoading", false);
        this.$store.commit("setMsgLoading", "");
        this.flagImportRetorno = true;
        this.max = data.dados;
        this.textImportacao = data.message;
        this.verificaImportacao(idImportacao);
      } catch (error) {
        this.showAlert = true;
        this.iconCheck = false;
        this.flagImportRetorno = false;
        this.variantAlert = "danger";
        this.msgAlert =
          "Ocorreu um erro na importação: " + error.response.data.message;
        this.$store.commit("setLoading", false);
        this.$store.commit("setFlagMsgLoading", false);
        this.$store.commit("setMsgLoading", "");
      }
    },

    async verificaImportacao(idImportacao) {
      try {
        let { data } = await this.http.get(
          `/admin/import/mercado/status/${idImportacao}`,
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
          }
        );
        if (data.erro) {
          this.iconCheck = false;
          this.variantAlert = "warning";
        } else {
          var othis = this;
          this.value = data.progress;
          if (data.completed != "1") {
            if(data.failed == "1"){
              this.showAlert = true;
              this.iconCheck = false;
              this.flagImportRetorno = false;
              this.variantAlert = "danger";
              this.msgAlert =
              "Ocorreu um erro na importação. Verificar com analista responsável.";
            } else {
              this.textImportacao =
                "Importação em andamento. Isso pode demorar alguns minutos. Aguarde a finalização!";
              this.$nextTick(() => {
                this.timerId = setTimeout(function () {
                  othis.verificaImportacao(idImportacao);
                }, 5000);
              });
            }
          } else {
            this.nomeArquivo = this.arquivo.name;
            this.dataImportacao = moment().format("DD/MM/YYYY HH:mm:ss");
            this.uploadedResult = true;
            this.showAlert = true;
            this.iconCheck = true;
            this.variantAlert = "success";
            this.msgAlert = "Importação finalizada com sucesso.";
            this.totaisRegistros.numeroRegistros = data.total_lines;
            this.totaisRegistros.importadosComSucesso = data.success;
            this.totaisRegistros.erros = data.errors;
            this.flagImportRetorno = false;
            this.arquivo = null;
          }
        }
      } catch (error) {
        if (error.response.data.error.code == 401) {
          this.verificaImportacao(idImportacao);
        }
      }
    },

    async gerarRelatorio() {
      try {
        this.$store.commit("setLoading", true);
        let params = {
          nomeArquivo: this.nomeArquivo,
        };
        const resp = await this.http.post(
          `/admin/relatorio/inconsistencias-arquivo-mercado`,params,
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
            responseType: 'blob'
          }
        );
          helpers.downloadFile(
            resp.data,
            "relatorio_inconsistencias_arquivo_mercado.xlsx"
          );
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.$store.commit("setLoading", false);
      }
    },

  },

  beforeRouteLeave(to, from, next) {
    //se o arquivo foi carregado mas a importacao ainda nao terminou
    if (this.flagImportRetorno) {
      const msg = `
                Deseja mesmo sair desta tela?
                Caso saia desta tela a importação continuará sendo executada, mas não será possível visualizar o resultado da mesma.
                `;
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: `Confirmação de saída da tela`,
          size: "md",
          buttonSize: "md",
          okVariant: "warning",
          okTitle: "Sair mesmo assim",
          cancelTitle: "Cancelar",
          headerClass: "bg-warning",
        })
        .then((value) => {
          if (value === true) {
            next();
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
          console.error(`Refuse to leave the page`, from, to);
        });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
</style>
