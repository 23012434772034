<template>
  <div class="mx-auto col-lg-9 col-xs-12">
    <div v-show="beneficiarios && beneficiarios.length > 0" id="result_main">
      <div>
        <div class="card px-4">
          <div class="card-body"></div>
          <lista-beneficiarios
            :mesAnoReferencia="mesAnoReferencia"
            :value="beneficiarios"
            :editable="periodoEdicao"
            @familia-inativada="$emit('familia-inativada')"
          ></lista-beneficiarios>
        </div>
      </div>
      <div v-show="beneficiarios && beneficiarios.length == 0" id="result_msg">
        <div class="card px-4">
          <div class="card-body"></div>
          <h4 class="mx-auto mb-5">
            <small class="text-muted">Nenhum registro encontrado</small>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "lista-beneficiarios": require("./resultado_busca/ListaBeneficiarios.vue")
      .default,
  },
  props: {
    mesAnoReferencia: { type: String, default: null },
  },
  data() {
    return {
      periodoEdicao: false,
    };
  },
  computed: {
    beneficiarios() {
      return this.$store.getters.lstBeneficiarios;
    },
    http() {
      return this.$store.getters.http;
    },
  },
  methods: {
    async verificaPeriodoEdicaoHabilitado() {
      try {
        this.$Progress.start();
        let { data } = await this.http.get(`/admin/fimdomes`, {
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        });
        if (data.erro === "true") {
          this.error = data.message;
        } else {
          this.periodoEdicao = !data.dados;
        }
        this.$store.commit("setLoading", false);
        this.$Progress.finish();
      } catch (error) {
        this.error = error;
        this.$store.commit("setLoading", false);
        this.$Progress.fail();
      }
    },
  },
  mounted() {
    this.$store.commit("setLstBeneficiarios", []);
    this.verificaPeriodoEdicaoHabilitado();
  },
};
</script>

<style>
</style>