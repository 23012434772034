<template>
  <b-card>
    <div v-if="beneficiario.situacao_familia == 'S'">
      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'child']"
            title="Nome do aluno de referẽncia"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Nome do aluno de referência</small></b-col
        >
        <b-col>{{ beneficiario.nome_aluno }}</b-col>
      </b-row>

      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'children']"
            title="Quantidade de dependentes"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Quantidade de dependentes</small></b-col
        >
        <b-col>{{ beneficiario.num_deps }}</b-col>
      </b-row>

      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'store']"
            title="Supermercado"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Supermercado</small></b-col
        >
        <b-col>{{ beneficiario.nome_exibicao }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'map-marked']"
            title="Endereço"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Endereço do supermercado</small></b-col
        >
        <b-col>{{ beneficiario.endereco_exibicao }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'shopping-basket']"
            title="Data"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Cesta retirada</small></b-col
        >
        <b-col>{{ cestaRetirada }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'calendar']"
            title="Data"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Data de retirada</small></b-col
        >
        <b-col v-if="beneficiario.data_retirada">{{ beneficiario.data_retirada }} | {{ beneficiario.turno }}</b-col>
        <b-col v-else></b-col>
      </b-row>
      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'tasks']"
            title="Tipo de benefício"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Tipo da cesta</small></b-col
        >
        <b-col>{{ beneficiario.tipo_produto }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'square-check']"
            title="Voucher visualizado"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Voucher visualizado</small></b-col
        >
        <b-col>{{ voucherVisualizado }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'calendar-check']"
            title="Data visualização voucher"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Data visualização voucher</small></b-col
        >
        <b-col>{{ beneficiario.data_visualizado }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'ticket']"
            title="Voucher"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Número do voucher</small></b-col
        >
        <b-col>
          {{ beneficiario.voucher }}
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'id-card']"
            title="Número INEP"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Número INEP</small></b-col
        >
        <b-col>{{ beneficiario.inep }}</b-col>
      </b-row>

      <b-row>
        <b-col cols="1" class="text-info">
          <font-awesome-icon
            :icon="['fas', 'chalkboard-teacher']"
            title="Nome da escola"
            class="mr-2"
          />
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          ><small>Nome da escola</small></b-col
        >
        <b-col>{{ beneficiario.nome_escola }}</b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row>
        <b-col>
          {{ beneficiario.familia_ativa }}
          <div class="text-muted">
            Voucher apenas é mostrado para famílias ativas
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    beneficiario: { type: Object, default: () => {} },
  },
  computed: {
    voucherVisualizado() {
      return this.beneficiario.flag_visualizou == "true"
        ? "Voucher já visualizado pelo cidadão"
        : "Voucher não visualizado";
    },
    cestaRetirada() {
      return this.beneficiario.flag_voucher_retirado == "true"
        ? "Cesta retirada no mercado"
        : "Cesta não retirada ou Dados ainda não atualizados pelo mercado";
    },
  },
};
</script>

<style></style>
