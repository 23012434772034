<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">Lista de parâmetros</div>
          <div class="card-body p-3">
            <b-table
              :fields="tabela.fields"
              :items="tabela.items"
              :current-page="tabela.currentPage"
              :per-page="tabela.perPage"
              responsive
              small
              stacked="md"
            >
              <template v-slot:cell(acoes)="data">
                <b-link
                  class="text-success ml-3"
                  title="Editar"
                  v-if="habilitarEdicao(data.item)"
                  @click.prevent="editar(data.item)"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" />
                </b-link>
              </template>
              <template v-slot:cell(ind_ativo)="data">
                <span v-if="data.item.ind_ativo == '1'" class="text-success">{{ status(data.item) }}</span>
                <span v-else class="text-danger">{{ status(data.item) }}</span>
              </template>
            </b-table>
            <div>
              <b-pagination
                align="right"
                v-if="tabela.totalRows > tabela.perPage"
                :total-rows="tabela.totalRows"
                :per-page="tabela.perPage"
                :value="tabela.currentPage"
                @change="mudarPagina"
              >
              </b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from "../helpers";

export default {
  components: {},
  data() {
    return {
      dataImportacao: "",
      nomeArquivo: "",
      usuario: "",
      items: [{ text: "Lista Parâmetros", active: true }],
      tabela: {
        currentPage: 1,
        items: [],
        fields: [
          {
            key: "mes_ano",
            label: "Mês/Ano Referência	"
          },
          {
            key: "data_inicio",
            label: "Data Inicial Validade Voucher"
          },
          {
            key: "data_fim",
            label: "Data Final Validade Voucher"
          },
          {
            key: "data_fechamento",
            label: "Data Fechamento Edição"
          },
          {
            key: "ind_ativo",
            label: "Status"
          },
          {
            key: "acoes",
            label: "Ações",
            class: "text-center"
          },
        ],
        perPage: 10,
        totalRows: 0,
        pageOptions: [10, 25, 50],
      },
    };
  },

  computed: {
    http() {
      return this.$store.getters.http;
    },
  },

  methods: {
    async listar() {
      try {
        this.$store.commit("setLoading", true);
        let { data } = await this.http.get(`/admin/lista-params`, {
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        });
        if (data.erro === "true") {
          this.error = data.message;
        } else {
          data.dados.forEach((element) => {
            element.mes_ano = element.mes + "/" + element.ano;
            element.data_inicio = helpers.formatDateHourToDate(
              element.data_inicio
            );
            element.data_fim = helpers.formatDateHourToDate(element.data_fim);
            element.data_fechamento = helpers.formatDateHourToDate(
              element.data_fechamento
            );
            this.tabela.items.push(element);
          });
          this.tabela.totalRows = this.tabela.items.length;
        }
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.$store.commit("setLoading", false);
      }
    },

    habilitarEdicao(item) {
      return item.ind_ativo == 1 ? true : false;
    },

    editar() {
      this.$router.push({
        name: "params"
      });
    },

    status(item) {
      return item.ind_ativo == 1 ? 'Ativo' : 'Inativo';
    },


  },

  mounted() {
    this.listar();
  },
};
</script>

