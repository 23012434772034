<template>
  <div>
    <div id="toastfeedback"
      :class="{ 'toast': true, 'bg-success-subtle': !!toastSuccess, 'bg-danger-subtle': !toastSuccess, 'position-fixed': true, 'bottom-0': true, 'end-0': true, 'p-1': true, 'mb-5': true, 'me-2': true }"
      style="width: 20em; z-index: 200" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div :class="{ 'toast-body': true }">{{ toastMsg }}</div>
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
    <!-- ============== input de filtro ==================== -->
    <b-row class="mt-1 pb-2 align-items-center" v-if="qtd > 0">
      <b-col cols="2">
        <select class="form-select" v-model="perPageModel">
          <option value="10" :selected="perPageModel == '10'">10</option>
          <option value="25" :selected="perPageModel == '25'">25</option>
          <option value="50" :selected="perPageModel == '50'">50</option>
        </select>
      </b-col>
      <b-col cols="2">
        <span style="font-size: smaller">registros por página</span>
      </b-col>
      <b-col>
        <b-input-group size="sm">
          <b-form-input v-model="filter" type="text" placeholder="Filtre aqui o resultado da pesquisa"
            @keyup.esc="filter = ''"></b-form-input>
          <b-input-group-append>
            <a class="btn btn-outline-warning" title="Limpar" :disabled="!filter" @click="filter = ''">
              <font-awesome-icon :icon="['fas', 'times']" />
            </a>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <!-- ==================== tabela ==================== -->
    <b-table hover small caption-top :items="itens" :fields="fields" stacked="md"
      :current-page="currPage" :busy="loading" :per-page="perPageModel" :filter="filter" :filterIncludedFields="['nome']"
      @filtered="onFiltered">
      <!-- ----------------------- detalhes ----------------------- -->
      <template v-slot:row-details="row">
        <detalhe-beneficiario :beneficiario="row.item"></detalhe-beneficiario>
      </template>

      <!-- ----------------- cpf (filtro) ------------------------- -->
      <template v-slot:cell(cpf)="row">
        <span style="font-size: .9em">{{ row.item.cpf | formataCpf }}</span>
      </template>

      <!-- ----------------- nome (editavel) ----------------------- -->
      <template v-slot:cell(nome)="row">
        <nome-editavel :edit="row.item.edit" :editable="editable" :beneficiario="row.item" :disabled="loading"
          @salvar="salvar" @cancelar="toggleEdit"></nome-editavel>
      </template>

      <!-- ----------------- tipo_produto (editavel) ----------------------- -->
      <template v-slot:cell(tipo_produto)="row">
        <tipo-cesta-editavel :edit="row.item.editTipoCesta" :editable="editable" :beneficiario="row.item"
          :disabled="loading" @salvar="abrirModalConfirmacao" @cancelar="toggleEditTipoCesta"></tipo-cesta-editavel>
      </template>

      <!-- ------------------ acoes -------------------------------- -->
      <template v-slot:cell(acoes)="row">
        <lista-acoes :disabled="loading" :editable="editable" :beneficiario="row.item"
          :editableTipoCesta="row.item.editable_tipo_cesta !== false" :active="row.item.situacao_familia === 'S'"
          @editarTipoCesta="toggleEditTipoCesta" @editar="toggleEdit" @visualizar="row.toggleDetails"
          @historico="historico" @confirma-inativar="toggle_inativar" @reativar="toggle_inativar" :mesAnoReferencia="mesAnoReferencia"></lista-acoes>
      </template>
    </b-table>

    <!-- ==================== paginacao ==================== -->
    <b-pagination v-model="currPage" :total-rows="qtd" :per-page="perPageModel" size="sm" class="mt-1" number-of-pages="5"
      align="right" v-if="qtd > 0"></b-pagination>

    <!-- ================ modal alteracao cesta ============ -->
    <b-modal ref="modalConfirmacao" centered title="Confirmação de Alteração de Tipo da Cesta"
      header-bg-variant="warning">
      <p style="font-size: larger; font-weight: bold">
        Deseja realmente alterar o tipo da cesta ?
      </p>
      <p class="text-danger" style="font-size: large; font-weight: bold">
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> Essa ação
        é irreversível.
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
      </p>
      <b-row class="mt-3">
        <b-col>
          <b-form-group label="Justificativa" invalid-feedback="Campo obrigatório para alteração.">
            <b-form-textarea placeholder="Digite pelo menos 4 caracteres" id="textarea" :maxlength="4000" rows="4"
              v-model="justificativa" :state="justificativa.length > 3"
              @keyup.native="removeAcentos($event)"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ }">
        <b-button variant="secondary" @click="toggleEditTipoCesta">
          Cancelar
        </b-button>
        <b-button variant="primary" :disabled="justificativa.length < 4" @click="antesAlterarTipoCesta()">
          SIM
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { Toast } from "bootstrap";

export default {
  components: {
    "detalhe-beneficiario": require("./DetalheBeneficiario.vue").default,
    "nome-editavel": require("./NomeEditavel.vue").default,
    "lista-acoes": require("./ListaAcoes.vue").default,
    "tipo-cesta-editavel": require("./TipoCestaEditavel.vue").default,
  },
  props: {
    editable: { type: Boolean, default: false },
    value: { type: Array, default: () => [{}] },
    paginate: { type: Boolean, default: true },
    perPage: { type: Number, default: 10 },
    mesAnoReferencia: { type: String, default: null },
  },
  data() {
    return {
      currPage: 1,
      perPageModel: 0,
      totalRows: null,
      benes: [],
      beneCurr: null,
      toastObj: null,
      loading: false,
      filter: "",
      justificativa: "",
      tipo_produto: null,
      toastMsg: '',
      toastSuccess: false,
    };
  },
  computed: {
    http() {
      return this.$store.getters.http;
    },
    qtd() {
      return this.totalRows || this.benes.length;
    },
    itens() {
      return this.benes;
    },
    fields() {
      return [
        { key: "cpf", label: "CPF", sortable: true, class: "text-left" },
        { key: "nome", label: "Nome", class: "text-left" },
        { key: "tipo_produto", label: "Tipo Cesta", class: "text-left" },
        { key: "acoes", label: "Ações", class: "text-center" },
      ];
    }
  },
  watch: {
    value() {
      this.benes = [...this.value];
    },
  },
  methods: {
    removeAcentos(e) {
      var texto = e.target.value;
      var semAcento = texto
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase();
      e.target.value = semAcento;
    },

    async abrirModalConfirmacao(bene) {
      this.tipo_produto = bene.tipo_produto;
      await this.$refs["modalConfirmacao"].show();
      // adaptacao da classe do bootstrap-vue para o bootstrap 5.3
      const closeButtons = document.getElementsByClassName("close");
      const arrCloseButtons = Array.from(closeButtons);
      arrCloseButtons.forEach(element => {
        element.classList.replace('close', 'btn-close');
        element.innerHTML = '';
      });
    },

    antesAlterarTipoCesta() {
      // se beneCurr estiver setado, eh pq ha alteracao no tipo de cesta do beneficiario
      if (this.beneCurr) {
        // atualiza o tipo de cesta no beneficiario a ser enviado ao backend
        this.beneCurr.tipo_produto = this.tipo_produto;
        // encontra o beneficiario correspondente ao beneCurr no array benes
        this.benes.forEach((element) => {
          if (element.control == this.beneCurr.control) {
            // atualiza o valor do tipo cesta
            element.tipo_produto = this.beneCurr.tipo_produto;
          }
        });
        this.salvar(this.beneCurr, true);
      }
    },

    async salvar(bene, tipoCesta = false) {
      this.$store.commit("setLoading", true);
      this.loading = true;
      this.$refs["modalConfirmacao"].hide();
      try {
        let { data } = await this.http.patch(
          `/admin/dados/${bene.id}`,
          {
            nome: bene.nome,
            tipo_produto: bene.tipo_produto,
            justificativa: this.justificativa,
            mes_cestas_pbhs_id: parseInt(bene.mes_cestas_pbhs_id),
            _method: "patch",
          },
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
          }
        );
        if (data.erro === "true") {
          this.error = data.erro.message;
          this.toast(data.erro.message, "danger", "Ops!");
        } else {
          this.toast(data.message);
          if (tipoCesta) {
            var index = this.benes.findIndex(
              (element) => element.control === bene.control
            );
            if (index > -1) {
              this.benes.splice(index, 1);
            }
          }
          this.benes = this.benes.map((item) => {
            if (item.control == bene.control) {
              item = { ...bene };
              item.edit = false;
              item.editTipoCesta = false;
              if (tipoCesta) {
                item.editable_tipo_cesta = false
                this.beneCurr = null;
              }
            }
            return item;
          });
        }
        this.$emit("input", this.benes);
        this.justificativa = "";
        this.$store.commit("setLstBeneficiarios", this.benes);
        this.loading = false;
        this.$store.commit("setLoading", false);
      } catch (e) {
        this.justificativa = "";
        this.loading = false;
        this.toast(e.message, "danger", "Ops!");
        this.$store.commit("setLoading", false);
      }
    },
    toggleEdit(beneficiario) {
      this.benes = this.benes.map((bene) => {
        if (bene.control == beneficiario.control) {
          bene.edit = beneficiario.edit ? false : true;
        }
        return bene;
      });
    },
    toggleEditTipoCesta(beneficiario) {
      this.beneCurr = beneficiario;
      this.benes = this.benes.map((bene) => {
        if (bene.control == beneficiario.control) {
          bene.editTipoCesta = beneficiario.editTipoCesta ? false : true;
        }
        return bene;
      });
      this.$refs["modalConfirmacao"].hide();
      this.justificativa = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currPage = 1;
    },
    toast(msg, variant = "success", title = "Sucesso!") {
      this.toastMsg = msg;
      this.toastSuccess = variant == 'success';
      this.toastObj['_config'].title = title;
      this.toastObj.show();

      // this.$bvToast.toast(msg, {
      //   title: title,
      //   autoHideDelay: 5000,
      //   appendToast: false,
      //   variant: variant,
      // });
    },
    historico(item) {
      this.$store.commit("setLstHistoricoVouchers", null);
      this.$store.commit("setTermoBuscaCpfHist", item.cpf);
      this.$router.push("admin/historico");
    },
    async toggle_inativar(bene) {
      this.loading = true;

      try {
        let { data } = await this.http.post(
          `/admin/ativacao/${bene.id}`,
          {
            _method: "patch",
          },
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
          }
        );
        if (data.erro === "true") {
          this.error = data.message;
          this.toast(this.error, "danger", "Ops!");
        } else {
          this.toast(data.message);
        }

        this.loading = false;
        this.$emit("familia-inativada", this.benes);
      } catch (e) {
        this.loading = false;
        this.toast(e.message, "danger", "Ops!");
      }
    },
  },
  mounted() {
    this.benes = [...this.value];
    this.perPageModel = this.perPage;
    let e = document.getElementById("toastfeedback");
    this.toastObj = Toast.getOrCreateInstance(e, { delay: 3000, appendToast: false });
    this.toastSuccess = true;
    this.toastObj.hide();
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5 !important;
}

.toast {
  z-index: 2000 !important;
}
</style>
