<template>
  <div id="textarea-commands" :class="{
    'textarea-commands': true,
    gavetaFechada: showBar,
    gavetaAberta: !showBar,
    'text-end': true,
    'me-4': true,
    'px-3': true,
  }">
    <div class="row row-cols-auto align-items-center">
      <span class="col" v-if="showBar">
        <input type="text" v-model="qtdLin" maxlength="2" size="4" alt="Número de registros a recuperar"
          title="Número de registros a recuperar" />
      </span>
      <ButtonRefresh v-if="showBar" title="Atualizar logs" text="Atualizar" height="1em" :clickHandler="handleRefresh"
        class="col pe-0"></ButtonRefresh>
      <ButtonCopy v-if="showBar" title="Copiar conteúdo dos logs" text="Copiar" height="1em" :clickHandler="handleCopy"
        class="col pe-0"></ButtonCopy>
      <ButtonDownload v-if="showBar" title="Baixar arquivo completo de logs" text="Baixar Log" height="1em"
        :clickHandler="handleDownload" class="col pe-0" :disabled="!imported"></ButtonDownload>
      <!-- <ButtonTrash v-if="showBar" title="Limpar conteúdo dos logs" text="Limpar" height="1em" :clickHandler="handleClear"
        class="col pe-0"></ButtonTrash> -->
      <ButtonUpload v-if="showBar" title="Realizar nova importação" text="Nova importação" height="1em"
        :clickHandler="handleNew" class="col pe-0" :disabled="!imported && !canceled"></ButtonUpload>
      <ButtonHide v-if="showBar" title="Esconder barra de comandos" height="1em" :clickHandler="handleHide"
        class="col pe-0"></ButtonHide>
      <ButtonShow v-if="!showBar" title="Mostrar barra de comandos" height="1em" :clickHandler="handleShow"
        class="col-11 px-0"></ButtonShow>
    </div>
  </div>
</template>
<script>
import ButtonRefresh from "./ButtonRefresh.vue";
import ButtonCopy from "./ButtonCopy.vue";
import ButtonDownload from "./ButtonDownload.vue";
import ButtonHide from "./ButtonHide.vue";
import ButtonShow from "./ButtonShow.vue";
// import ButtonTrash from "./ButtonTrash.vue";
import ButtonUpload from "./ButtonUpload.vue";

export default {
  props: {
    show: { type: Boolean, default: true },
    value: { type: String },
    fileLoaded: { type: Boolean, default: false },
    imported: { type: Boolean, default: false },
    canceled: { type: Boolean, default: false },
  },
  components: {
    ButtonRefresh,
    ButtonCopy,
    ButtonDownload,
    ButtonHide,
    ButtonShow,
    // ButtonTrash,
    ButtonUpload,
  },
  data() {
    return {
      showBar: true,
      qtdLin: "5",
    };
  },
  watch: {
    qtdLin() {
      this.$emit("input", this.qtdLin);
    },
  },
  methods: {
    handleRefresh() {
      this.$emit("refresh");
    },
    handleCopy() {
      this.$emit("copy");
    },
    handleDownload() {
      this.$emit("download");
    },
    handleClear() {
      this.$emit("clear");
    },
    handleNew() {
      this.$emit("new");
    },
    handleHide() {
      this.showBar = false;
    },
    handleShow() {
      this.showBar = true;
    },
  },
  mounted() {
    this.showBar = this.show;
  },
};
</script>
<style>
.textarea-commands {
  position: absolute;
  top: 6%;
  right: 1%;
  box-shadow: 0.1em 0.05em 0.4em gray;
  background-color: lightgray;
  padding: 0.2em 0.5em 0.2em 0.5em;
  max-width: 90%;
  z-index: 10;
  font-size: small;
  font-family: "Courier New", Courier, monospace;
}

.gavetaAberta {
  transform: translateX(2%);
  transition: transform ease-in 0.3s;
}

.gavetaFechada {
  min-width: 4em;
  transform: translateX(0%);
  transition: transform ease-out 0.3s;
}

@media screen and (max-width: 480px) {
  .textarea-commands {
    position: relative;
    background-color: lightgray;
    padding: 0.5em 1em 0.5em 1em;
    margin-bottom: 2em;
    max-width: 100%;
    width: 100%;
  }

  .gavetaAberta {
    transform: translateX(0%);
    width: 100%;
    transition: transform ease-in 0.3s;
  }

  .gavetaFechada {
    min-width: 4em;
    transform: translateX(0%);
    width: 100%;
    transition: transform ease-out 0.3s;
  }
}
</style>
