<template>
  <b-card>
    <b-row>
      <b-col cols="1" class="text-info">
        <font-awesome-icon :icon="['fas', 'shopping-basket']" title="Supermercado" class="mr-2" />
      </b-col>
      <b-col>{{ historico.nome_exibicao }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="1" class="text-info">
        <font-awesome-icon :icon="['fas', 'map-marked']" title="Endereço" class="mr-2" />
      </b-col>
      <b-col>{{ historico.endereco_exibicao }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="1" class="text-info">
        <font-awesome-icon :icon="['fas', 'people-carry']" title="Produto retirado?" class="mr-2" />
      </b-col>
      <b-col>{{ historico.desc_retirou_voucher }}</b-col>
    </b-row>
        <b-row>
      <b-col cols="1" class="text-info">
        <font-awesome-icon :icon="['fas', 'calendar']" title="Data de retirada" class="mr-2" />
      </b-col>
      <b-col>{{ historico.data_retirada | dateToString }} </b-col>
    </b-row>
    <b-row>
      <b-col cols="1" class="text-info">
        <font-awesome-icon :icon="['fas', 'users']" title="Situação da família" class="mr-2" />
      </b-col>
      <b-col>{{ historico.desc_situacao_familia }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="1" class="text-info">
        <font-awesome-icon :icon="['fas', 'ticket-alt']" title="Voucher" class="mr-2" />
      </b-col>
      <b-col>{{ historico.voucher }}</b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
    props: {
        historico: { type: Object, default: () => {} }
    },
};
</script>

<style>
</style>