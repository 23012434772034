<template>
  <div>
    <div v-if="edit && editable" class="input-group input-group-sm">
      <b-form-select name="tipo_produto" id="tipo_produto" :options="options"
        :class="`form-control` + ($v.tipo_produto.$error ? ` is-invalid` : ``)" v-model.trim="$v.tipo_produto.$model"
        @change="$v.tipo_produto.$touch()" @keyup.enter="salvar(beneficiario)" @keyup.esc="cancelar(beneficiario)" />
      <div class="input-group-append">
        <button :disabled="disabled" class="btn btn-success" @click="salvar(beneficiario)" title="Salvar">
          <font-awesome-icon :icon="['fas', 'check']" />
        </button>
      </div>
      <div class="input-group-append">
        <button :disabled="disabled" class="btn btn-secondary" @click="$emit('cancelar', beneficiario)" title="Cancelar">
          <font-awesome-icon :icon="['fas', 'times']" />
        </button>
      </div>
    </div>
    <span style="font-size: .9em" v-else>{{ descricaoTipoCesta(beneficiario.tipo_produto) }}</span>
    <div class="text-danger" v-if="edit && $v.tipo_produto.$error">
      <div v-if="!$v.tipo_produto.required">
        <small>Este campo é obrigatório.</small>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import helpers from '../../../helpers/index'

export default {
  props: {
    beneficiario: { type: Object, default: () => { } },
    disabled: { type: Boolean, default: false },
    edit: { type: Boolean, default: false }, // modo edicao, alterado com o clique do botao
    editable: { type: Boolean, default: true }, // para casos em que nao eh permitido editar, p.ex. de acordo com o perfil
  },
  data() {
    return {
      tipo_produto: "",
      options: [],
    };
  },
  validations: {
    tipo_produto: {
      required,
    },
  },
  watch: {
    edit() {
      if (!this.edit) this.tipo_produto = this.beneficiario.tipo_produto;
    },
  },
  methods: {
    descricaoTipoCesta(tipo) {
      return helpers.getDescricaoTipoCesta(tipo);
    },
    salvar(beneficiario) {
      if (parseInt(beneficiario.tipo_produto) != this.tipo_produto) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          let newBene = { ...beneficiario };
          newBene.tipo_produto = this.tipo_produto;
          this.$emit("salvar", newBene);
        }
      }
    },
    cancelar(beneficiario) {
      this.tipo_produto = this.beneficiario.tipo_produto;
      this.$emit("cancelar", beneficiario);
    },
    verificaPeriodo() {
      this.options = [];
      var janeiro = moment("2024-01-31");
      var atual = moment();
      if (atual.isBefore(janeiro, 'day')) {
        this.options.push(
          { value: 1, text: "1 - Cesta Pequena (Janeiro)" },
          { value: 2, text: "2 - Cesta Grande (Janeiro)" }
        );
      } else {
        this.options.push(
          { value: 3, text: "3 - Cesta Pequena (Julho)" },
          { value: 4, text: "4 - Cesta Grande (Julho)" }
        );
      }
    },
  },
  mounted() {
    this.tipo_produto = this.beneficiario.tipo_produto;
    this.verificaPeriodo();
  },
};
</script>

<style></style>