<template>
  <div class="row">
    <div class="col-10 col-md-8">
      <div class="progress p-1" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
        aria-valuemax="100">
        <div :class="{ 'progress-bar': true, 'bg-warning': true }"
          :style="'width: ' + percent + '%' + '; font-size: .8em; color: black'"><b>{{ percent }}%</b></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percent: { type: String },
  },
  data() {
    return {};
  },
  watch: {
    percent() { },
  },
  computed: {},
  methods: {},
  async mounted() { },
};
</script>

<style scoped></style>
