<template>
  <div class="d-flex">
    <b-link
      v-if="canEdit && exibirBtns"
      :disabled="disabled"
      class="text-success"
      style="margin-right: 3px;"
      title="Editar"
      @click.prevent="$emit('editar', beneficiario)"
    >
      <font-awesome-icon :icon="['fas', 'edit']" />
    </b-link>
    <b-link
      v-if="canEditTipoCesta && beneficiario.tipo_produto && exibirBtns"
      :disabled="disabled"
      style="margin-right: 3px;"
      @click.prevent="$emit('editarTipoCesta', beneficiario)"
      title="Editar Tipo Cesta"
    >
      <b-icon-bag-x-fill class="mb-1" variant="secondary"></b-icon-bag-x-fill>
    </b-link>
    <b-link
      v-if="canRemove && !this.active && exibirBtns"
      id="reativar"
      :disabled="disabled"
      style="margin-right: 3px;"
      class="text-success ml-3"
      title="Reativar família"
      @click.prevent="$emit('reativar', beneficiario)"
    >
      <font-awesome-icon :icon="['fas', 'trash-restore-alt']" />
    </b-link>
    <span class="dropleft" v-if="canRemove && this.active && exibirBtns">
      <b-link
        :disabled="disabled"
        class="text-danger ml-3 dropdown-toggle"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Inativar família"
      >
        <font-awesome-icon :icon="['fas', 'trash-alt']" />
      </b-link>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <h6 class="dropdown-header text-dark">
          Confirma inativação da família?
        </h6>
        <small>
          <a
            id="confirma-inativar"
            class="dropdown-item text-danger"
            href="#"
            @click.prevent="remover"
            >Sim</a
          >
        </small>
        <small>
          <a
            id="cancela-inativar"
            class="dropdown-item text-secondary"
            href="#"
            @click.prevent="cancelar"
            >Cancela</a
          >
        </small>
      </div>
    </span>
    <b-link
      :disabled="disabled"
      style="margin-right: 3px;"
      class="text-info ml-3"
      title="Visualizar"
      @click.prevent="$emit('visualizar')"
    >
      <font-awesome-icon :icon="['fas', 'eye']" />
    </b-link>
    <b-link
      :disabled="disabled"
      style="margin-right: 3px;"
      class="text-warning ml-3"
      title="Histórico"
      @click.prevent="$emit('historico', beneficiario)"
    >
      <font-awesome-icon :icon="['fas', 'clock']" />
    </b-link>
  </div>
</template>

<script>
export default {
  props: {
    beneficiario: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    editableTipoCesta: { type: Boolean, default: false },
    active: { type: Boolean, default: true },
    mesAnoReferencia: { type: String, default: null },
  },
  computed: {
    canRemove() {
      if (this.can("action-remove-familia")) return this.editable;
      return false;
    },
    canEdit() {
      if (this.can("action-edit-nome-visualizado")) return this.editable;
      return !this.voucherVisualizado && this.editable;
    },
    canEditTipoCesta() {
      if (this.can("action-edit-tipo-cesta")) return this.editableTipoCesta;
      return false;
    },
    voucherVisualizado() {
      return this.beneficiario.flag_visualizou === "true";
    },
    exibirBtns() {
      if (this.mesAnoReferencia == this.beneficiario.mes_ano_cesta) return true;
      return false;
    },
  },
  methods: {
    remover() {
      if (!this.disabled) this.$emit("confirma-inativar", this.beneficiario);
    },
    cancelar() {
      if (!this.disabled) this.$emit("cancela-inativar", this.beneficiario);
    },
  },
};
</script>

<style scoped>
.dropdown-item.active,
.dropdown-item:active {
  background-color: lightgoldenrodyellow !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: rgba(100, 200, 200, 0.5) !important;
}
</style>
