class Paginacao {
    constructor(
        {
            total = 0, current_page = 1, per_page = 10,
            last_page = 0, first_page_url = null, last_page_url = null,
            next_page_url = null, prev_page_url = null
        } = {}
    ) 
    {
        this.total = total;
        this.current_page = current_page;
        this.per_page = per_page;
        this.last_page = last_page;
        this.first_page_url = first_page_url;
        this.last_page_url = last_page_url;
        this.next_page_url = next_page_url;
        this.prev_page_url = prev_page_url;
    }
}

export default Paginacao;