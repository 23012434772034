<template>
  <div class="mx-auto col-lg-9 col-xs-12">
    <div v-show="historicos && historicos.length>0" id='result_historicos'>
      <div>
        <div class="card px-4">
          <div class="card-body"></div>
          <lista-historicos :value="historicos"></lista-historicos>
        </div>
      </div>
      <div v-show="historicos && historicos.length==0">
        <div class="card px-4">
          <div class="card-body"></div>
          <h4 class="mx-auto mb-5">
            <small class="text-muted">Nenhum registro encontrado</small>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "lista-historicos": require("./resultado_busca_historico/ListaHistoricos.vue")
      .default
  },
  data() {
    return {
    };
  },
  computed: {
    historicos() {
      return this.$store.getters.lstHistoricoVouchers;
    }
  },
  methods: {
  },
  mounted() {
    this.$store.commit("setLstHistoricoVouchers", []);
  }
};
</script>

<style>
</style>