<template>
  <div>
    <div class="alert alert-warning" v-if="error">{{ error }}</div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">Pesquise por</span>
      </div>
      <b-form-input
        class="form-control"
        id="busca_cpf"
        type="text"
        placeholder="CPF"
        :class="`form-control` + ($v.busca_cpf.$error ? ` is-invalid` : ``)"
        v-model.trim="$v.busca_cpf.$model"
        v-if="showCpf"
        v-mask="'###.###.###-##'"
        @change="$v.busca_cpf.$touch()"
        @keyup.enter="buscar"
        @keyup.esc="limpar"
      ></b-form-input>
      <input
        class="form-control"
        id="busca_nome"
        type="text"
        placeholder="Nome"
        :class="`form-control` + ($v.busca_nome.$error ? ` is-invalid` : ``)"
        v-model.trim="$v.busca_nome.$model"
        v-if="showNome"
        @change="$v.busca_nome.$touch()"
        @keyup.enter="buscar"
        @keyup.esc="limpar"
      />
      <div class="input-group-append">
        <a class="btn btn-outline-warning" title="Limpar" @click="limpar">
          <font-awesome-icon :icon="['fas', 'times']" />
        </a>
      </div>
      <div class="input-group-append">
        <a class="btn btn-outline-success" title="Pesquisar" @click="buscar">
          <font-awesome-icon :icon="['fas', 'search']" />
        </a>
      </div>
    </div>

    <div
      class="mt-0 text-danger"
      v-if="
        (showCpf && $v.busca_cpf.$error) || (showNome && $v.busca_nome.$error)
      "
    >
      <div v-if="!$v.busca_cpf.requiredIf || !$v.busca_nome.requiredIf">
        <small>Preencha pelo menos um termo para a busca.</small>
      </div>
      <div v-if="!$v.busca_cpf.isCpf">
        <small>CPF inválido.</small>
      </div>
      <div v-if="!$v.busca_nome.sobrenome">
        <small>Digite apenas letras e pelo menos um sobrenome.</small>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf, helpers } from "vuelidate/lib/validators";
import { validaCPF } from "../../validations";
//import { TheMask } from "vue-the-mask";

export default {
  props: {
    showCpf: { type: Boolean, default: true },
    showNome: { type: Boolean, default: true },
    isHistorico: { type: Boolean, default: false },
    searchOnMount: { type: Boolean, default: false },
  },
  components: {
    //TheMask,
  },
  data() {
    return {
      busca_cpf: "",
      busca_nome: "",
      error: null,
    };
  },
  validations: {
    busca_cpf: {
      requiredIf: requiredIf((nestedModel) => !nestedModel.busca_nome),
      isCpf: (value) => {
        if (value) return validaCPF(value);
        return true;
      },
    },
    busca_nome: {
      requiredIf: requiredIf((nestedModel) => !nestedModel.busca_cpf),
      sobrenome: helpers.regex(
        "sobrenome",
        /^[A-Za-zÀ-ÖØ-öø-ÿ']* [A-Za-zÀ-ÖØ-öø-ÿ ']*$/
      ),
    },
  },

  computed: {
    http() {
      return this.$store.getters.http;
    },
  },
  methods: {
    async buscar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$Progress.start();
        this.$emit("buscar", { cpf: this.busca_cpf, nome: this.busca_nome });
        this.error = null;
        this.$store.commit("setLoading", true);
        const busca_cpf = this.busca_cpf.replace(/[!@#$%^&.\-*]/g, "");
        if (this.isHistorico) {
          await this.buscarHistoricos(busca_cpf);
        } else {
          await this.buscarDefault(busca_cpf);
        }

        this.$store.commit("setLoading", false);
        this.$Progress.finish();
      }
    },
    async buscarDefault(busca_cpf) {
      this.$store.commit("setLstBeneficiarios", []);
      this.$store.commit("setTermoBuscaCpf", this.busca_cpf);
      this.$store.commit("setTermoBuscaNome", this.busca_nome);
      try {
        const busca =
          busca_cpf && this.busca_nome
            ? `${busca_cpf}/${this.busca_nome}`
            : busca_cpf
            ? busca_cpf
            : this.busca_nome;

        let { data } = await this.http.get(`/admin/busca/${busca}`, {
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        });
        if (data.erro === "true") {
          this.error = data.message;
        } else {
          const beneficiarios = data.dados.map((beneficiario) => {
            return {
              id: beneficiario.id,
              uuid: beneficiario.uuid,
              control: Math.random(),
              mes_cestas_pbhs_id: beneficiario.mes_cestas_pbhs_id,
              nome: beneficiario.nome_responsavel,
              cpf: beneficiario.cpf_resp,
              nome_exibicao: beneficiario.nome_exibicao,
              endereco_exibicao: beneficiario.endereco_exibicao,
              data_retirada: beneficiario.data_retirada,
              turno: beneficiario.turno,
              tipo_produto: beneficiario.tipo_produto,
              flag_visualizou: beneficiario.flag_visualizou,
              data_visualizado: beneficiario.data_visualizado,
              situacao_familia: beneficiario.situacao_familia,
              familia_ativa: beneficiario.familia_ativa,
              voucher: beneficiario.voucher,
              num_deps: beneficiario.quantidade_dependente,
              inep: beneficiario.inep,
              nome_escola: beneficiario.nome_escola,
              nome_aluno: beneficiario.nome_aluno,
              flag_voucher_retirado: beneficiario.flag_voucher_retirado,
              mes_ano_cesta: beneficiario.mes+'/'+beneficiario.ano
            };
          });
          this.$store.commit("setLstBeneficiarios", beneficiarios);
        }
      } catch (error) {
        this.error = error;
        this.$store.commit("setLoading", false);
        this.$Progress.fail();
      }
    },
    async buscarHistoricos(busca_cpf) {
      this.$store.commit("setLstHistoricoVouchers", []);
      try {
        let { data } = await this.http.get(`/admin/historico/${busca_cpf}`, {
          headers: { Authorization: `Bearer ${this.$store.getters.token}` },
        });
        if (data.erro === "true") {
          this.error = data.message;
        } else {
          const historicos = data.dados.map((historico) => {
            return {
              nome: historico.nome,
              cpf: historico.cpf,
              nome_exibicao: historico.supermercado,
              endereco_exibicao: historico.endereco_supermercado,
              voucher: historico.voucher,
              flag_retirou_voucher: historico.flag_voucher_retirado,
              desc_retirou_voucher: historico.produto_retirado,
              mes: `${historico.mes}/${historico.ano}`,
              flag_situacao_familia: historico.situacao_ativa,
              desc_situacao_familia: historico.familia_ativa,
              data_retirada: historico.data_retirada,
            };
          });
          this.$store.commit("setLstHistoricoVouchers", historicos);
        }
      } catch (error) {
        this.error = error;
        this.$store.commit("setLoading", false);
        this.$Progress.fail();
      }
    },
    limpar() {
      this.error = null;
      this.$v.$reset();
      if (this.showCpf) {
        this.busca_cpf = "";
      }
      if (this.showNome) {
        this.busca_nome = "";
      }
    },
  },
  mounted() {
    if (this.isHistorico) {
      this.busca_cpf =
        this.showCpf && this.$store.getters.termoBuscaCpfHist
          ? this.$store.getters.termoBuscaCpfHist
          : "";
    } 
    if (this.searchOnMount){
      this.buscar();
    } else{
      this.busca_cpf = "";
    }
  },
};
</script>
